import { ApolloClient, InMemoryCache, createHttpLink, MutationOptions, QueryOptions } from '@apollo/client';
import * as query from './queries';
import * as mutation from './mutations';
import * as helpers from './helpers';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_MARKET_GRAPHQL_API,
});
const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });
const graphqlClient = new ApolloClient({
  link: persistedQueriesLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

/**
 * Function execute mutation without hooks
 * @example  mutate(mutation.UPDATE_CLOSED_DATE)(formValues)
 */
const mutate =
  <TVariables = any>(mut: MutationOptions['mutation'], isContext?: boolean) =>
  (input?: TVariables, options?: Omit<MutationOptions<any, TVariables>, 'mutation'>) =>
    graphqlClient
      .mutate({ ...options, mutation: mut, variables: isContext ? input : { input }, ...options })
      .then(helpers.mapMutationResponseResult)
      .catch(helpers.mapMutationErrorResponse);

/**
 * Function execute query without hooks
 * @example  query(queryCall.CLOSED_DATES)
 */
const queryCall =
  <TVariables = any>(query: QueryOptions['query']) =>
  (input?: TVariables, options?: Omit<MutationOptions<TVariables, any>, 'query'>) =>
    graphqlClient
      .query({ ...options, query, variables: { input, ...options } })
      .then(helpers.mapQueryResponseResult)
      .then(helpers.mapQueryErrorResponse);

export { query, graphqlClient, mutate, queryCall, mutation };
export * from './types';
