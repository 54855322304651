import { gql } from '@apollo/client';

export const MARKET_ADDRESS_FIELDS = gql`
  fragment AddressFields on Market_LocationAddress {
    city
    state
    address
    zipCode
    details
  }
`;

export const MARKET_SERVICE_GROUPS_FIELDS = gql`
  fragment ServiceGroupFields on Market_ServiceGroup {
    name
    description
    services {
      id
      name
      description
      price {
        amount
        currency
      }
      duration
    }
  }
`;

export const MARKET_WORKING_HOURS_FIELDS = gql`
  fragment WorkingHoursFields on Market_LocationWorkingHours {
    monday {
      open
      opensAt
      closesAt
    }
    tuesday {
      open
      opensAt
      closesAt
    }
    wednesday {
      open
      opensAt
      closesAt
    }
    thursday {
      open
      opensAt
      closesAt
    }
    friday {
      open
      opensAt
      closesAt
    }
    saturday {
      open
      opensAt
      closesAt
    }
    sunday {
      open
      opensAt
      closesAt
    }
  }
`;

export const MARKET_PARTNER = gql`
  query MarketPartner($context: Context, $slug: String!) {
    partner(context: $context, slug: $slug) {
      slug
      name
      description
      locations {
        id
        slug
        name
        photos
        address {
          city
          state
          address
        }
      }
    }
  }
`;

export const MARKET_LOCATION = gql`
  ${MARKET_ADDRESS_FIELDS}
  ${MARKET_WORKING_HOURS_FIELDS}
  ${MARKET_SERVICE_GROUPS_FIELDS}
  query MarketLocation($context: Context, $slug: String!, $previewToken: String) {
    location(context: $context, slug: $slug, previewToken: $previewToken) {
      slug
      id
      name
      email
      description
      phone
      workingHours {
        ...WorkingHoursFields
      }
      address {
        ...AddressFields
      }
      serviceGroups {
        ...ServiceGroupFields
      }
      photos
      partner {
        currency
        importantInformation
        allowEmployeeSelection
        description
        onlineRequiredField
        appointmentChangeTime
        serviceLimit
        facebookTrackerId
        googleTrackerId
        latestAppointmentTime
        timeFormat
        paymentTypes
        paymentSecret
        paymentAccountId
      }
    }
  }
`;

export const MARKET_EMPLOYEES = gql`
  query MarketEmployees($context: Context, $locationSlug: String!, $serviceIds: [Uuid!]) {
    employees(context: $context, locationSlug: $locationSlug, serviceIds: $serviceIds) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          firstName
          lastName
          photo
        }
      }
    }
  }
`;

export const MARKET_TIME_SLOTS = gql`
  query MarketTimeSlots(
    $context: Context
    $locationSlug: String!
    $startDate: Date!
    $endDate: Date!
    $serviceIds: [Uuid!]!
    $employeeId: Uuid
  ) {
    timeSlots(
      context: $context
      locationSlug: $locationSlug
      startDate: $startDate
      endDate: $endDate
      serviceIds: $serviceIds
      employeeId: $employeeId
    ) {
      closed
      date
      timeSlots
    }
  }
`;

export const MARKET_APPOINTMENT = gql`
  ${MARKET_ADDRESS_FIELDS}
  query MarketAppointment($context: Context, $id: Uuid!, $entryToken: String) {
    appointment(context: $context, id: $id, entryToken: $entryToken) {
      id
      date
      location {
        id
        name
        email
        description
        slug
        photos
        address {
          ...AddressFields
        }
        phone
      }
      status
      isCancellable
      partner {
        id
        currency
        timeFormat
      }
      bookings {
        id
        service {
          id
          name
          description
          price {
            amount
            currency
          }
          duration
        }
        employee {
          firstName
          lastName
        }
        duration
        startTime
      }
    }
  }
`;
