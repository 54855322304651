import styled from 'styled-components';

export const BottomControl = styled.footer`
  border-top: 1px solid #e6e8ec;
  height: 90px;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 3;
  background-color: #fcfcfd;
  padding: 24px 20px;
  justify-content: center;
  button {
    min-width: 133px;
    background: #225ffe;
    border: none;
    svg {
      margin-left: 5px;
    }
  }
  .ant-btn-primary[disabled] {
    color: #ffffff;
    background: #cfd1d7;
    border: none;
  }

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    align-items: center;
    button {
      min-width: 141px;
    }
  }
`;
