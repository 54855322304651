import format from 'date-fns/format';
import pick from 'lodash/pick';
import React from 'react';
import { CustomIcon, formatTime, Text } from '@plandok/core';
import { MarketLocationWorkingHours } from 'ghql';
import * as SC from './styles';

type LocationWorkingHoursProps = {
  workingHours?: MarketLocationWorkingHours;
  oneDay?: boolean;
  isMobile: boolean;
  timeFormat: string;
};

export const LocationWorkingHours = ({ workingHours, oneDay, isMobile, timeFormat }: LocationWorkingHoursProps) => {
  const today = Object.values(pick(workingHours, format(new Date(), 'EEEE').toLowerCase()))[0];

  const getWorkingDaysLabels = (day: string): string => {
    switch (day) {
      case 'monday':
        return 'calendar.mon';
      case 'tuesday':
        return 'calendar.tue';
      case 'wednesday':
        return 'calendar.wed';
      case 'thursday':
        return 'calendar.thu';
      case 'friday':
        return 'calendar.fri';
      case 'saturday':
        return 'calendar.sat';
      case 'sunday':
        return 'calendar.sun';
      default:
        return '';
    }
  };

  return (
    <>
      {oneDay ? (
        <SC.HoursContainer>
          {today.open ? (
            <>
              <CustomIcon type="timeCircle" />
              <Text
                label="ob.hours.open"
                mb="none"
                size={isMobile ? 'base' : 'small'}
                weight="semiBold"
                ml="small"
                colorType="darkBrown"
              />
              <Text mb="none" size={isMobile ? 'base' : 'small'} ml="xsmall" colorType="darkBrown">
                {formatTime(today.opensAt, timeFormat)} - {formatTime(today.closesAt, timeFormat)}
              </Text>
            </>
          ) : (
            <>
              <CustomIcon type="timeCircle" />
              <Text
                colorType="darkBrown"
                label="market.wh.closed.title"
                mb="none"
                size={isMobile ? 'base' : 'small'}
                weight="semiBold"
                ml="small"
              />
            </>
          )}
        </SC.HoursContainer>
      ) : (
        <SC.FullWeekHoursContainer>
          <SC.SansText
            size="mlarge"
            weight="medium"
            mb={isMobile ? 'small' : 'large'}
            label="market.location.workingHours.title"
            colorType="darkBrown"
          />

          {workingHours &&
            Object.entries(workingHours)?.map(([key, subject], index) => (
              <div key={index}>
                {key !== '__typename' && (
                  <SC.FullWeekHoursWrapper>
                    <Text
                      colorType="darkBrown"
                      label={getWorkingDaysLabels(key)}
                      mb="none"
                      size="small"
                      weight="semiBold"
                    />

                    {subject.open ? (
                      <Text mb="none" size="small" ml="small" colorType="darkBrown">
                        {formatTime(subject.opensAt, timeFormat)} - {formatTime(subject.closesAt, timeFormat)}
                      </Text>
                    ) : (
                      <Text label="market.wh.closed.title" mb="none" size="small" ml="small" colorType="darkBrown" />
                    )}
                  </SC.FullWeekHoursWrapper>
                )}
              </div>
            ))}
        </SC.FullWeekHoursContainer>
      )}
    </>
  );
};
