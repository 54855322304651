import React, { useState } from 'react';
import { message } from 'antd';
import { Button, withModal, CustomIcon, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from '../styles';

function CancelAppointmentModal({ modalProps, onClose }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [, { showModal }] = useModal();

  const handleConfirmModal = async () => {
    const cancelAppointmentError = modalProps.mutationError;

    setIsLoading(true);

    await modalProps.cancelAppointment({
      variables: {
        context: modalProps.context,
        input: {
          appointmentId: modalProps.appointmentId,
          entryToken: modalProps.entryToken,
        },
      },
    });

    setIsLoading(false);
    onClose();

    if (cancelAppointmentError) {
      message.error(<IntlLabel label={cancelAppointmentError} />);
    } else {
      modalProps.setIsShowCancelButton(false);
      await modalProps.refetch();
      showModal({ type: 'SUCCESS_CANCEL_APPOINTMENT' });
    }
  };

  return (
    <SC.CancelAppointmentContainer>
      <CustomIcon type="cancelAppointmentCalendar" />
      <SC.SansText
        label="market.modal.cancelAppointment.title"
        colorType="brown"
        weight="medium"
        size="large"
        mb="medium"
      />
      <SC.ButtonsContainer>
        <Button
          label="market.btn.cancel"
          upperCase={false}
          bold
          block
          onClick={handleConfirmModal}
          loading={isLoading}
        />
        <Button whiteBtn label="btn.close" onClick={onClose} upperCase={false} bold block />
      </SC.ButtonsContainer>
    </SC.CancelAppointmentContainer>
  );
}

export default withModal(null, { contentClassName: 'cancel-appointment-modal' })(CancelAppointmentModal);
