import styled, { css } from 'styled-components';
import { mediaSmDown } from '@plandok/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const Wrapper = styled.div`
  background-image: url(${({ theme }) => theme.background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 950px;
  height: 685px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;

    div {
      margin-top: 100px;
    }
    button {
      padding: 0 42px;
      border: 2px solid #373737;
      color: #373737;
      box-shadow: none;
    }
  }
  ${mediaSmDown(css`
    width: 327px;
    height: 376px;
  `)}
`;
