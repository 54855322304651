import { FunctionComponent } from 'react';
import { SelectLanguageModal } from '@plandok/core';
import SuccessCancelAppointmentModal from '../modals/SuccessCancelAppointmentModal';
import CancelAppointmentModal from '../modals/CancelAppointmentModal';

type ModalMap = {
  [key: string]: FunctionComponent;
};

export const ModalMap: ModalMap = {
  LANGUAGE_SELECT: SelectLanguageModal,
  CANCEL_APPOINTMENT: CancelAppointmentModal,
  SUCCESS_CANCEL_APPOINTMENT: SuccessCancelAppointmentModal,
};
