import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import { Select } from 'antd';
import { Text, validateRequired } from '@plandok/core';
import { ReactIntl } from '@plandok/i18n';
import { MarketEmployee, MarketEmployeesResponse, query } from 'ghql';
import StaffSelectRow from './components/DropdownStaffItem';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import FlowStatusBlock from 'components/FlowStatusBlock';
import useMediaQuery from 'hooks/screen/use-media-query';
import BookTimeSelect from 'components/BookTimeSelect';
import * as SC from '../../../styles';
import { useStore } from 'store';
import './style.less';

interface StaffAndDateSelectStepProps {
  values: any;
  step: number;
}

export default function StaffAndDateSelectStep({ values, step }: StaffAndDateSelectStepProps) {
  const setEmployees = useStore((state) => state.setEmployees);
  const setFormValues = useStore((state) => state.setFormValues);
  const setEmployeeId = useStore((state) => state.setEmployeeId);
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const [overlay, setOverlay] = useState(false);
  const { lang } = useContext(ReactIntl);

  const isAllowEmployeeSelection = values?.partner.allowEmployeeSelection;
  const serviceIds = values?.serviceIds;
  const locationSlug = values?.slug;
  const context = { language: lang };

  const { data: employeesData, loading: employeesLoading } = useQuery<MarketEmployeesResponse>(query.MARKET_EMPLOYEES, {
    variables: { context, locationSlug, serviceIds },
  });

  const employees = employeesData?.employees?.edges?.map((e) => e?.node)?.filter(Boolean) as MarketEmployee[];

  setEmployees(employees);
  setFormValues(values);

  const onSelected = (value: any) => {
    setEmployeeId(value);
    setOverlay(false);
  };

  const onDropdownVisibleChange = (open: boolean) => setOverlay(open);
  const onBlur = () => setOverlay(false);

  return (
    <div className={isMobile ? 'justify-center' : 'justify-between'}>
      {!isMobile && <SC.Block />}

      <SC.ContentWrapper theme={{ isMobile }}>
        {isAllowEmployeeSelection && (
          <>
            <Text size="base" colorType="darkBrown" label="input.staff.placeholder" mb="small" weight="semiBold" />

            <SC.StaffSelect
              onChange={onSelected}
              onDropdownVisibleChange={onDropdownVisibleChange}
              onBlur={onBlur}
              loading={employeesLoading}
              defaultValue={<StaffSelectRow isNoPreference />}
              theme={{ overlay }}
            >
              <Select.Option value={undefined}>
                <StaffSelectRow isNoPreference />
              </Select.Option>

              {employees?.map((employee) => (
                <Select.Option key={employee.id} value={employee.id}>
                  <StaffSelectRow employee={employee} />
                </Select.Option>
              ))}
            </SC.StaffSelect>
          </>
        )}

        {!isMobile && (
          <Text size="base" colorType="darkBrown" label="input.pickDateAndTime.title" mb="small" weight="semiBold" />
        )}

        <SC.PickDateAndTime theme={{ isAllowEmployeeSelection }}>
          <Field
            name="datetime"
            component={BookTimeSelect}
            validate={validateRequired}
            values={values}
            isMobile={isMobile}
          />
        </SC.PickDateAndTime>
      </SC.ContentWrapper>

      {!isMobile && <FlowStatusBlock values={values} step={step} />}

      {isMobile && overlay && <SC.CustomOverlay />}
    </div>
  );
}
