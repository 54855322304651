import { message as antMessage } from 'antd';
import { ApolloError } from '@apollo/client';
import { FORM_ERROR } from 'final-form';

const getErrorMessage = (validation: any): string | undefined => {
  return validation?.[0]?.message;
};

const transformErrorObjectKey = (key: string) => {
  return key?.replace('input.', '');
};

// TODO add correct mapping to match existing logic
export const mapQueryErrorResponse = (res: any) => {
  return res;
};

// TODO add correct mapping to match existing logic
export const mapQueryResponseResult = (res: any) => {
  try {
    return res.data;
  } catch (error) {
    antMessage.error('Failed to perform action');
    return null as any;
  }
};

export const mapMutationErrorResponse = (res: ApolloError) => {
  let errors = res.graphQLErrors
    ?.map((e) => e.extensions?.validation)
    ?.reduce((acc, e) => {
      return {
        ...acc,
        ...Object.keys(e || {})
          .filter((k) => getErrorMessage(e?.[k]))
          .reduce((acc, k) => ({ ...acc, [transformErrorObjectKey(k)]: getErrorMessage(e?.[k]) }), {}),
      };
    }, {});
  const globalError = res.graphQLErrors
    ?.map((e) => e.message)
    .filter(Boolean)
    .join('\n');

  const [firstErrorsKey] = Object.keys(errors);
  const validationError = `${firstErrorsKey} validation error: ${errors[firstErrorsKey]}`;

  if (globalError) {
    errors[FORM_ERROR] = globalError;
  }
  if (!!firstErrorsKey?.length) {
    errors = { ...errors, error: validationError };
  }

  return { errors };
};

// TODO add correct mapping to match existing logic
export const mapMutationResponseResult = (res: any) => {
  return res;
};
