import { gql } from '@apollo/client';

export const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment($context: Context, $input: Market_CreateAppointmentInput!) {
    createAppointment(context: $context, input: $input) {
      success
      entryToken
      clientSecret
      paymentStatus
      appointmentId
    }
  }
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($context: Context, $input: Market_CancelAppointmentInput!) {
    cancelAppointment(context: $context, input: $input) {
      success
    }
  }
`;
