import { Divider } from 'antd';
import React from 'react';
import { Button, CustomIcon, formatTime, Text } from '@plandok/core';
import useFormatDate from 'hooks/use-format-date';
import { MarketLocationService } from 'ghql';
import { useStore } from 'store';
import * as SC from './styles';

interface FlowStatusBlockProps {
  values: any;
  step?: number;
}

export default function FlowStatusBlock({ values, step }: FlowStatusBlockProps) {
  const setAssignedSpecialist = useStore((state) => state.setAssignedSpecialist);
  const setSelectedServices = useStore((state) => state.setSelectedServices);
  const employeeId = useStore((state) => state.employeeId);
  const employees = useStore((state) => state.employees);

  const { name, address, photos, submitting, onNext, serviceIds, serviceGroups, datetime } = values;

  const assignedSpecialistFirstName = employees?.find(({ id }) => id === employeeId)?.firstName;
  const assignedSpecialistLastName = employees?.find(({ id }) => id === employeeId)?.lastName;
  const assignedSpecialist = {
    id: employeeId,
    firstName: assignedSpecialistFirstName,
    lastName: assignedSpecialistLastName,
  };

  const { timeFormat } = values?.partner;
  const bookTime = (datetime?.time || datetime?.time === 0) && formatTime(datetime.time, timeFormat);
  const bookDate = useFormatDate('EEEE, dd MMM', datetime?.date);
  const selectedServices: MarketLocationService[] = [];
  const mappedServices: MarketLocationService[] = [];
  const isServiceSelected = !!serviceIds?.length;
  const methodPay = values?.methodPay;
  const fourthStep = step === 4;

  serviceGroups?.map(({ services }: { services: MarketLocationService[] }) => mappedServices.push(...services));

  serviceIds?.forEach((serviceId: string) => {
    const filteredServices = mappedServices?.filter(({ id }) => id === serviceId);

    selectedServices.push(...filteredServices);
  });

  setAssignedSpecialist(assignedSpecialist);
  setSelectedServices(selectedServices);

  const totalPrice = selectedServices?.reduce((acc, val) => acc + parseInt(val?.price.amount), 0);

  return (
    <>
      <SC.CardContainer theme={{ fourthStep }}>
        <SC.CardWrapper>
          <SC.CustomCard>
            <SC.ImageContainer theme={{ background: photos[0] }}>
              <Text mb="none" colorType="white" size="small" weight="semiBold">
                {name}
              </Text>
            </SC.ImageContainer>

            <SC.InfoContainer>
              {bookDate && (
                <Text mb="small" size="large" colorType="black" weight="medium">
                  {bookDate}
                </Text>
              )}

              {bookTime && (
                <SC.TimeContainer>
                  <div className="d-flex">
                    <CustomIcon type="locationTime" />
                  </div>
                  <Text mb="none" size="base" ml="small" colorType="black">
                    {bookTime}
                  </Text>
                </SC.TimeContainer>
              )}

              <div className="align-center">
                <div className="d-flex">
                  <CustomIcon type="locationTransparent" />
                </div>
                <Text mb="none" size="base" ml="small" colorType="black">
                  {address?.address ? `${address?.address}.` : ''}
                  {address?.city ? ` ${address?.city}` : ''}
                  {address?.zipCode ? `, ${address?.zipCode}` : ''}
                </Text>
              </div>

              {employeeId && (
                <SC.AssignEmployeeContainer>
                  <div className="d-flex">
                    <CustomIcon type="assignedEmployee" />
                  </div>
                  <Text mb="none" size="base" ml="small" colorType="black">
                    {assignedSpecialistFirstName} {assignedSpecialistLastName}
                  </Text>
                </SC.AssignEmployeeContainer>
              )}

              <Divider className="mt-2 mb-2" />

              {!selectedServices?.length && (
                <SC.NoDataContainer>
                  <Text label="market.ob.noServices.placeholder" mb="none" size="base" />
                </SC.NoDataContainer>
              )}

              {isServiceSelected && (
                <>
                  {selectedServices?.map(({ id, name, price, duration }) => (
                    <SC.ServiceContainer key={id}>
                      <div className="justify-between">
                        <Text mb="none" size="base" colorType="black">
                          {name}
                        </Text>
                        {!!parseInt(price.amount) && (
                          <Text mb="none" size="base" colorType="black" weight="medium">
                            {price.amount} {price.currency}
                          </Text>
                        )}
                      </div>
                      <div className="d-flex">
                        <Text size="small" mb="none" colorType="lightGrey">
                          {duration}
                        </Text>
                        <Text size="small" mb="none" label="market.ob.service.duration.title" colorType="lightGrey" />
                      </div>
                    </SC.ServiceContainer>
                  ))}
                </>
              )}

              <Divider className="mt-2 mb-2" />

              <div className="justify-between">
                <Text mb="none" label="appointment.total" colorType="black" />
                <Text mb="none" weight="semiBold" colorType="black">
                  {isServiceSelected ? `${totalPrice} ${selectedServices[0].price.currency}` : 0}
                </Text>
              </div>
            </SC.InfoContainer>
          </SC.CustomCard>
        </SC.CardWrapper>
      </SC.CardContainer>

      {fourthStep && (
        <SC.ButtonContainer>
          <Button
            type="primary"
            label={methodPay === 'site' ? 'btn.confirmBook' : 'btn.confirmPay'}
            loading={submitting}
            htmlType="submit"
            onClick={onNext}
            upperCase={false}
          />
        </SC.ButtonContainer>
      )}
    </>
  );
}
