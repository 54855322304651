import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Text, useLocation, useParams, useQueryParams, CustomIcon, useModal } from '@plandok/core';
import { ReactIntl, LanguageNameMapping } from '@plandok/i18n';
import { MarketLocationResponse, MarketLocationService, MarketLocationServiceGroups, query } from 'ghql';
import { LocationCarouselAbout } from './components/LocationCarouselAbout';
import { LocationWorkingHours } from './components/LocationWorkingHours';
import { LocationCarousel } from './components/LocationCarousel';
import { LocationServices } from './components/LocationServices';
import { LocationAddress } from './components/LocationAddress';
import { LocationHeader } from './components/LocationHeader';
import { getMetaTagURL } from 'helpers/labels/app-meta-url';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import InfinitySpinner from 'components/InfinitySpinner';
import useMediaQuery from 'hooks/screen/use-media-query';
import NotFoundPage from 'pages/NotFoundPage';
import MetaData from 'components/MetaData';
import * as SC from './styles';
import { useStore } from '../../store';

export default function LocationPage() {
  const { navigate } = useLocation();

  const { lang } = useContext(ReactIntl);
  const setTrackerIds = useStore((state) => state.setTrackerIds);

  const [isVisibleHeaderAndFooter, setIsVisibleHeaderAndFooter] = useState(false);
  const [heightToButton, setHeightToButton] = useState(0);

  const [, { showModal }] = useModal();
  const params: any = useParams();
  const queryParams: any = useQueryParams();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  useEffect(() => {
    const listenToScroll = () => {
      const heightToHideFrom = isMobile ? heightToButton : 30;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

      winScroll > heightToHideFrom
        ? !isVisibleHeaderAndFooter && setIsVisibleHeaderAndFooter(true)
        : setIsVisibleHeaderAndFooter(false);
    };

    window.addEventListener('scroll', listenToScroll);

    return () => window.removeEventListener('scroll', listenToScroll);
  }, [isMobile, heightToButton, isVisibleHeaderAndFooter]);

  const previewToken = queryParams.get('previewToken');
  const slug = params?.slug;
  const context = { language: lang };
  const navigateUrl = params.locale ? `/${lang}/${slug}/checkout` : `/${slug}/checkout`;

  const { data: locationData, loading: locationLoading } = useQuery<MarketLocationResponse>(query.MARKET_LOCATION, {
    variables: { context, slug, previewToken },
  });
  const location = locationData?.location;

  useEffect(() => {
    if (location) {
      setTrackerIds({
        googleTrackerId: location.partner.googleTrackerId,
        facebookTrackerId: location.partner.facebookTrackerId,
      });
    }
  }, [location, setTrackerIds]);

  const serviceGroups: MarketLocationServiceGroups[] = location?.serviceGroups ?? [];
  const filteredServices: MarketLocationServiceGroups[] = serviceGroups?.filter(({ services }) => services.length);
  const serviceItems = filteredServices?.map((serviceGroup) => {
    const services: MarketLocationService[] = serviceGroup?.services;
    return {
      title: serviceGroup.name,
      description: serviceGroup.description,
      services,
    };
  });

  const bookNow = () => navigate(navigateUrl);

  const openSelectLanguage = () => showModal({ type: 'LANGUAGE_SELECT', modalProps: { slug } });

  const timeFormat = location?.partner.timeFormat;
  const locationName = location?.name ? `${location?.name} - ` : '';
  const locationAddress = location?.address?.address ? `${location?.address?.address} - ` : '';
  const locationCity = location?.address?.city ? `${location?.address?.city} | Plandok` : '';
  const metaTitle = `${locationName}${locationAddress}${locationCity}`;
  const countOfAllServices = serviceItems.reduce((acc, { services }) => acc + services.length, 0);
  const allowBooking = locationData?.location?.serviceGroups?.some(
    ({ services }: { services: MarketLocationService[] }) => services.length
  );

  return (
    <>
      {locationLoading ? (
        <InfinitySpinner />
      ) : (
        <>
          {location && timeFormat ? (
            <>
              <MetaData title={metaTitle} location={location} url={getMetaTagURL(params, slug)} />
              {!isMobile && isVisibleHeaderAndFooter && (
                <LocationHeader bookNow={bookNow} openSelectLanguage={openSelectLanguage} />
              )}
              <SC.Container>
                <SC.Card>
                  <SC.ContentWithSlider isOneColumn={location?.photos?.length === 0}>
                    {isMobile && (
                      <>
                        {!!location?.photos?.length && (
                          <SC.LocationPhotos>
                            <LocationCarousel photos={location?.photos} buttons={false} overlay />
                          </SC.LocationPhotos>
                        )}

                        <SC.LocationContent isOneColumn={location?.photos?.length === 0}>
                          <LocationAddress
                            location={location}
                            isTitle
                            isBookNow={allowBooking}
                            bookNow={bookNow}
                            isAddress
                            isWorkingHours
                            isMobile={isMobile}
                            isVisibleHeaderAndFooter={isVisibleHeaderAndFooter}
                            heightToButton={heightToButton}
                            setHeightToButton={setHeightToButton}
                            timeFormat={timeFormat}
                          />
                        </SC.LocationContent>
                      </>
                    )}

                    {!isMobile && (
                      <>
                        <SC.LocationContent isOneColumn={location?.photos?.length === 0}>
                          <LocationAddress
                            location={location}
                            isTitle
                            isBookNow={allowBooking}
                            bookNow={bookNow}
                            isAddress
                            isWorkingHours
                            isMobile={isMobile}
                            isVisibleHeaderAndFooter={isVisibleHeaderAndFooter}
                            heightToButton={heightToButton}
                            setHeightToButton={setHeightToButton}
                            timeFormat={timeFormat}
                          />
                        </SC.LocationContent>

                        {!!location?.photos?.length && (
                          <SC.LocationPhotos>
                            <LocationCarousel photos={location?.photos} buttons={location?.photos.length > 1} />
                          </SC.LocationPhotos>
                        )}
                      </>
                    )}
                  </SC.ContentWithSlider>
                </SC.Card>

                <SC.AdaptiveDivider />

                {!!serviceItems.length && (
                  <LocationServices isMobile={isMobile} categories={serviceItems} bookNow={bookNow} />
                )}

                <SC.AdaptiveDivider />

                <SC.BottomContentLocation id="about">
                  <SC.BottomContentLocationInfo>
                    <SC.SansText
                      size={isMobile ? 'xlarge' : 'xxxlarge'}
                      weight="semiBold"
                      mb={isMobile ? 'xsmall' : 'medium'}
                      label="market.location.aboutUs.title"
                      colorType="brown"
                    />
                    {location?.partner?.description && (
                      <Text size="small" mb="none" colorType="black">
                        {location.partner.description}
                      </Text>
                    )}
                  </SC.BottomContentLocationInfo>

                  <LocationCarouselAbout
                    photos={location?.photos ?? []}
                    buttons={!isMobile && Boolean(location?.photos && location.photos.length > 3)}
                    isMobile={isMobile}
                  />
                </SC.BottomContentLocation>

                <SC.AdaptiveDivider />

                <SC.BottomContent>
                  <div>
                    <SC.SansText
                      size="mlarge"
                      label="market.location.title"
                      weight="medium"
                      mb={isMobile ? 'small' : 'large'}
                      colorType="darkBrown"
                    />
                    <LocationAddress
                      location={location}
                      isPhone
                      isMobile={isMobile}
                      isVisibleHeaderAndFooter={isVisibleHeaderAndFooter}
                      heightToButton={heightToButton}
                      setHeightToButton={setHeightToButton}
                      timeFormat={timeFormat}
                    />
                  </div>
                  {location?.workingHours && (
                    <LocationWorkingHours
                      workingHours={location?.workingHours}
                      isMobile={isMobile}
                      timeFormat={timeFormat}
                    />
                  )}
                  <SC.PlandokLogo>
                    <div>
                      <CustomIcon type="plandokLogo" />
                      <SC.SansText
                        label="market.poweredByPlandok.title"
                        weight="medium"
                        size="mlarge"
                        mb="small"
                        colorType="darkBrown"
                      />
                      <Text label="market.poweredByPlandok.description" size="small" colorType="darkBrown" mb="none" />
                      <a href="https://plandok.com" target="_blank" rel="noopener noreferrer">
                        <Text label="plandok.com " colorType="link" size="small" mb="none" />
                      </a>
                    </div>
                  </SC.PlandokLogo>
                </SC.BottomContent>

                {isMobile && (
                  <SC.Footer>
                    <Button onClick={openSelectLanguage}>
                      <CustomIcon type="globe" />
                      <Text mb="none" ml="xsmall" size="base" colorType="darkBrown">
                        {LanguageNameMapping[lang]}
                      </Text>
                    </Button>
                  </SC.Footer>
                )}

                {isVisibleHeaderAndFooter && (
                  <SC.BottomControl>
                    <div data-bottom-control-content>
                      {!isMobile && (
                        <SC.SansText mb="none" weight="medium" colorType="darkBrown">
                          {location?.name}
                        </SC.SansText>
                      )}
                      <SC.AllowBooking>
                        <div className="align-center">
                          <Text mb="none" size="base" colorType="darkBrown" weight="semiBold">
                            {countOfAllServices}
                          </Text>
                          <Text
                            mb="none"
                            size="base"
                            colorType="darkBrown"
                            label="market.servicesAvailable.title"
                            ml="xxsmall"
                          />
                        </div>
                        <Button type="primary" label="market.btn.bookNow" onClick={bookNow} upperCase={false} bold />
                      </SC.AllowBooking>
                    </div>
                  </SC.BottomControl>
                )}
              </SC.Container>
            </>
          ) : (
            <NotFoundPage />
          )}
        </>
      )}
    </>
  );
}
