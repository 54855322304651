import React, { FunctionComponent } from 'react';
import { ModalMap } from './constants/constants';
import { useModal } from '@plandok/core';

type ModalProps = {
  type: string;
  modalProps: { [key: string]: any };
  visible: boolean;
};

type ModalComponentProps = {
  modalProps: { [key: string]: any };
  onClose: () => void;
  hideAllModals: () => void;
  visible: boolean;
};

export default function ModalContainer() {
  const [{ modals }, { hideModal, hideAllModals }] = useModal();

  return modals.map(({ type, modalProps, visible }: ModalProps) => {
    const ModalComponent: FunctionComponent<ModalComponentProps> = ModalMap[type];

    const hideCurrentModal = () => {
      hideModal(type);
    };

    return (
      <ModalComponent
        key={type}
        modalProps={modalProps}
        onClose={hideCurrentModal}
        hideAllModals={hideAllModals}
        visible={visible}
      />
    );
  });
}
