import styled from 'styled-components';
import { Button } from '@plandok/core';

export const ServicesContainer = styled.div`
  max-width: 1140px;
  margin: 48px auto 74px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 2rem);
    margin: 24px auto 48px;
  }
`;

export const BtnViewMoreWrapper = styled.div`
  text-align: center;
  margin-top: 16px;

  button {
    box-shadow: none;
    padding: 8px 25px;
    border: 2px solid #e6e8ec;
    max-height: 40px;
  }
`;

export const ServicesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ServicesSticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 122px;
  height: max-content;
  margin-right: 15px;
`;

export const ServicesBlock = styled.div`
  width: 753px;
  min-height: max-content;
`;

export const ButtonService = styled(Button)`
  display: block;
  box-shadow: none !important;
  border: none;
  background: ${({ theme }) => (theme.selected ? '#353945 !important' : '#fcfcfd !important')};
  min-height: 34px;
  white-space: normal;
  height: max-content;
  padding: 5px 15px;
  text-align: start;

  div {
    transition: all 0.3s;
    color: ${({ theme }) => (theme.selected ? '#fcfcfd' : '#777e90')};
    line-height: 24px;
    font-weight: ${({ theme }) => (theme.selected ? '600' : '400')};
  }
  :hover {
    background: ${({ theme }) => !theme.selected && '#fcfcfd !important'};
    div {
      color: ${({ theme }) => !theme.selected && '#353945'};
    }
  }
`;
