import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { MarketLocation } from 'ghql';

type MetaDataProps = {
  title: string;
  location: MarketLocation | undefined | null;
  url: string;
};

export const MetaData = ({ title, location, url }: MetaDataProps) => {
  const [meta] = useState({
    title,
    description: location?.description || '',
    url: url,
    imgUrl: (location?.photos || [])[0] || '',
  });

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.imgUrl} />
    </Helmet>
  );
};

export default MetaData;
