import React from 'react';
import { CustomIcon, Text } from '@plandok/core';
import { MarketAppointmentLocationAddress } from 'ghql';

type LocationAddressProps = {
  address: MarketAppointmentLocationAddress;
};

export default function LocationAddress({ address }: LocationAddressProps) {
  return (
    <div className="align-center">
      <div className="d-flex">
        <CustomIcon type="locationTransparent" />
      </div>
      <Text mb="none" size="base" ml="small" colorType="black">
        {address?.address ? `${address?.address}.` : ''}
        {address?.city ? ` ${address?.city}` : ''}
        {address?.zipCode ? `, ${address?.zipCode}` : ''}
      </Text>
    </div>
  );
}
