import styled from 'styled-components';
import { Divider } from 'antd';
import { Text } from '@plandok/core';

type ComponentProps = {
  isOneColumn?: boolean;
};

export const Container = styled.div`
  margin: 30px auto 90px;
  width: 100%;
  max-width: 1284px;
  padding-bottom: 1px;

  [data-book-now] {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    min-height: 55px;
    border-radius: 36px;
    padding: 16px 42px;
    margin-top: 32px;
    @media screen and (max-width: 1024px) {
      margin: 30px auto 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
  }
`;

export const Card = styled.div`
  max-width: 1140px;
  margin: 0 auto 96px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;

export const AdaptiveDivider = styled(Divider)`
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 2rem);
    min-width: auto;
  }
`;

export const ContentWithSlider = styled.div<ComponentProps>`
  display: grid;
  grid-template-columns: ${({ isOneColumn }) => (isOneColumn ? '1fr' : '45% 55%')};
  height: 620px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    height: auto;
  }
`;

export const LocationContent = styled.div<ComponentProps>`
  height: inherit;
  display: flex;
  flex-direction: column;

  ${({ isOneColumn }: ComponentProps) => {
    if (isOneColumn)
      return `
      @media screen and (max-width: 1024px) {
        display: flex;
      }
    `;
    if (!isOneColumn)
      return `
      @media screen and (max-width: 1024px) {
        margin-top: 25px;
      }
    `;
  }}
`;

export const LocationPhotos = styled.div`
  .ant-carousel {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: inline-block;
      height: 620px;
      @media screen and (max-width: 1024px) {
        height: 100vw;
        border-radius: initial;
      }
    }
  }
`;

export const BottomControl = styled.footer`
  height: 90px;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
  background-color: #fcfcfd;
  border-top: 1px solid #e6e8ec;

  [data-bottom-control-content] {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      max-width: 100vw;
    }
  }
`;

export const AllowBooking = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 24px;
    padding: 10px 46px;
    background-color: #3b71fe;
    border: none;
  }

  @media screen and (max-width: 1024px) {
    button {
      margin-left: 29px;
      padding: 9px 32px;
    }
  }
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 63px auto 48px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: calc(100% - 2rem);
    margin: 24px auto 40px;
    align-items: center;
    text-align: center;
  }
`;

export const SansText = styled(Text)`
  font-family: 'DM Sans', sans-serif;
`;

export const PlandokLogo = styled.div`
  display: flex;
  align-items: center;
  div {
    text-align: center;
  }
  svg {
    margin-bottom: 10px;
  }
`;

export const BottomContentLocation = styled.div`
  scroll-margin-top: 122px;
  max-width: 1140px;
  margin: 64px auto;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 2rem);
    margin: 24px auto;
    text-align: justify;
  }
`;

export const BottomContentLocationInfo = styled.div`
  max-width: 681px;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  margin-bottom: 128px;
  button {
    margin: 0 auto;
    padding: 0 30px;
    background-color: inherit;
    box-shadow: none;
    display: flex;
    align-items: center;
    &:hover,
    &:active,
    &:focus {
      color: #2D2825;
    }
    svg {
      path {
        stroke: #2D2825;
      }
    }
  }
}
`;
