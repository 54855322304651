import AppointmentPage from './pages/AppointmentPage';
import BookFlowPage from './pages/BookFlowPage';
import LocationPage from './pages/LocationPage';
import NotFoundPage from './pages/NotFoundPage';
import { RoutePath } from './constants/route';
import PartnerPage from './pages/PartnerPage';

type Routes = {
  exact?: boolean;
  path?: string;
  component: () => JSX.Element;
};

export const routes: Routes[] = [
  {
    exact: true,
    path: RoutePath.LOCATION_PAGE,
    component: LocationPage,
  },
  {
    exact: false,
    path: RoutePath.BOOK_FLOW_PAGE,
    component: BookFlowPage,
  },
  {
    exact: false,
    path: RoutePath.APPOINTMENT_PAGE,
    component: AppointmentPage,
  },
  {
    exact: false,
    path: RoutePath.PARTNERS_PAGE,
    component: PartnerPage,
  },
  {
    component: NotFoundPage,
  },
];

export const baseRoutes = [
  {
    exact: true,
    path: `${RoutePath.BASE_URL}${RoutePath.LOCATION_PAGE}`,
    component: LocationPage,
  },
  {
    exact: false,
    path: `${RoutePath.BASE_URL}${RoutePath.BOOK_FLOW_PAGE}`,
    component: BookFlowPage,
  },
  {
    exact: false,
    path: `${RoutePath.BASE_URL}${RoutePath.APPOINTMENT_PAGE}`,
    component: AppointmentPage,
  },
  {
    exact: false,
    path: `${RoutePath.BASE_URL}${RoutePath.PARTNERS_PAGE}`,
    component: PartnerPage,
  },
];
