import { IconButton } from '@plandok/core';
import React, { useRef } from 'react';
import * as SC from './styles';

type LocationCarouselAboutProps = {
  photos: string[];
  buttons: boolean;
  isMobile: boolean;
};

export const LocationCarouselAbout = ({ photos, buttons, isMobile }: LocationCarouselAboutProps) => {
  const carouselRef: any = useRef(null);

  const handlePrev = () => carouselRef.current.prev();
  const handleNext = () => carouselRef.current.next();

  const settings = {
    dots: false,
    slidesToShow: isMobile ? (photos.length > 2 ? 2 : photos.length) : photos.length > 3 ? 3 : photos.length,
  };

  return (
    <>
      {buttons && (
        <SC.ButtonsContainer>
          <IconButton icon="arrowSlider" onClick={handlePrev} />
          <IconButton icon="arrowSlider" onClick={handleNext} />
        </SC.ButtonsContainer>
      )}

      <SC.CustomCarousel ref={carouselRef} {...settings} theme={{ specialWidth: photos.length <= 2 }}>
        {photos?.map((photo, index) => (
          <SC.Img src={photo} alt={`{image}-${index}`} key={index} />
        ))}
      </SC.CustomCarousel>
    </>
  );
};
