import { Card, Text, mediaSmDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const CardInformation = styled(Card)`
  box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
  border-radius: 10px;
  padding: 40px 32px 32px;
  width: 480px;
  margin-bottom: 48px;

  @media screen and (max-width: 1024px) {
    width: calc(100vw - 40px);
    padding: 40px 24px 20px;
  }
`;

export const Block = styled.div`
  min-width: 250px;
`;

export const ContentContainer = styled.div`
  .ant-form-item-no-colon {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 21px;
    color: #2d2825 !important;
  }

  .ant-input,
  .ant-select-selection {
    border-radius: initial;
    border: none !important;
    border-bottom: 1px solid #adb6cc !important;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2d2825;
    padding: 6px 24px 8px;
    outline: none;
  }

  .ant-input-group {
    .ant-input {
      padding-left: 0;
    }
  }

  .has-error .ant-input,
  .has-error .ant-input:hover {
    border-color: #f5222d !important;
  }

  .ant-input::placeholder,
  .ant-select-selection::placeholder {
    color: #777e90;
  }

  .ant-col-md-11 {
    padding-right: 0 !important;
  }

  .ant-select-selection__rendered {
    margin-right: 36px !important;
  }

  .ant-select-arrow {
    svg {
      color: #2d2825;
    }
  }

  .ant-input-group-addon {
    border: none !important;
  }

  .ant-select-selection.ant-select-selection--single {
    padding: 0;
    padding-left: 13px;
    border: none !important;
    border-bottom: 1px solid #adb6cc !important;
  }

  .ant-select-selection-selected-value {
    color: #2d2825;
    padding-right: 2px;
  }

  .ant-input:focus,
  .ant-select-selection.ant-select-selection:focus {
    border-bottom: 1px solid #2d2825 !important;
    box-shadow: none !important;
  }

  .ant-input:-webkit-autofill {
    box-shadow: inset 0 0 0 50px #ffffff !important;
  }

  .ant-form-explain {
    min-height: 32px;
  }

  textarea.ant-input {
    margin-bottom: 0 !important;
    resize: vertical;
  }

  .ant-form-item-label {
    @media screen and (max-width: 575px) {
      padding: 0;
    }
  }

  .ant-radio-inner {
    background-color: #ffffff;
    border-color: #e6e8ec;
  }

  .ant-radio-inner::after {
    display: none;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 5px solid #225ffe;
  }

  .ant-input[type='number']::-webkit-outer-spin-button,
  .ant-input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .ant-input[type='number'],
  .ant-input[type='number']:hover,
  .ant-input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 80px;
  }
`;

export const FieldsRequiredTitle = styled(Text)`
  margin-top: -8px;
  color: #777e90;
`;

export const PaymentMethodTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
  div {
    div {
      color: #777e90;
    }
  }
`;

export const CardPayment = styled(Card)`
  box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
  border-radius: 10px;
  padding: 45px 32px 16px;
  width: 480px;
  margin-bottom: 48px;

  @media screen and (max-width: 1024px) {
    width: calc(100vw - 40px);
    padding: 29px 16px 8px;
  }
`;

export const CardPayCard = styled.div<{ disabled?: boolean }>`
  border-radius: 10px;
  border: ${({ theme }) => (theme.selected ? '2px solid #225FFE' : '1px solid #e6e8ec')};
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'none')};
  padding: 19px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  ${mediaSmDown(css`
    padding: 14px 16px 15px;
  `)}
`;

export const WrapperIconsCards = styled.div`
  display: flex;
  div {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  svg {
    margin-left: 8px;
  }

  ${mediaSmDown(css`
    flex-direction: column;

    div:first-of-type {
      margin-bottom: 4px;
    }
  `)}
`;

export const BlockPayCard = styled.div`
  margin-top: 28px;
`;

export const CVVDateWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  gap: 32px;
  & > div {
    width: 100%;
  }
`;

export const BorderWrapper = styled.div`
  border: solid ${({ error }: { error: boolean }) => (error ? '#f5222d' : '#adb6cc')};
  border-width: 0 0 1px 0;

  &.focused {
    border: solid #2d2825;
    border-width: 0 0 1px 0;
  }
`;

export const Padding = styled.div`
  padding: 6px 24px 8px;
`;

export const CardPaySite = styled.div`
  border-radius: 10px;
  border: ${({ theme }) => (theme.selected ? '2px solid #225FFE' : '1px solid #e6e8ec')};
  cursor: pointer;
  padding: 19px 16px;
  display: flex;
  margin-bottom: 12px;

  ${mediaSmDown(css`
    padding: 20px 16px;
  `)}
`;

export const BlockPaySite = styled.div`
  width: 432px;
  margin: 8px auto 32px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 28px);
    margin: 0 auto 32px;
  }
`;

export const CardAdditionalInformation = styled(Card)`
  box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
  border-radius: 10px;
  padding: 32px;
  width: 480px;

  @media screen and (max-width: 1024px) {
    width: calc(100vw - 40px);
    padding: 32px 16px;
  }
`;

export const ImportantInfoContainer = styled.div`
  background: #e0f3fd;
  border: 1px solid #95d2f2;
  border-radius: 5px;
  padding: 12px 20px;
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    padding: 12px 16px;
  }
`;
