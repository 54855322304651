import { Card, Text, Button, mediaMobileDown, mediaXlDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const DescriptionBusiness = styled.div`
  max-width: 611px;
  word-break: break-word;
`;

export const LessText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ButtonRead = styled(Button)`
  font-weight: 500;
  line-height: 160%;
  box-shadow: none !important;
  background-color: inherit !important;
  border: none;
  padding: 0;
  color: #7f8596 !important;
  max-height: 26px;
`;

export const Container = styled.div`
  margin: 0 auto 87px;
  width: 100%;
  max-width: 1140px;
  overflow: hidden;
  grid-gap: 48px 24px;
  display: grid;
  grid-template-columns: ${({ theme }) =>
    theme.count < 3 ? 'repeat(auto-fit, minmax(335px, 0fr))' : 'repeat(auto-fit, minmax(335px, 1fr))'};

  ${mediaMobileDown(css`
    grid-gap: 40px;
    margin: 0 auto 39px;
    width: 90%;
  `)}
`;

export const ContainerCard = styled(Card)`
  max-width: 362px;
  border: none;
  border-radius: 10px;
  background: #f4f5f6;
  text-align: center;
  height: 100%;
`;

export const ContainerAddress = styled.div`
  padding: 16px 24px 14px;
  text-align: start;
  height: 100%;
`;

export const WrapperAddress = styled.div`
  display: flex;
  align-items: center;
  div {
    color: #ababab;
  }

  svg {
    width: 14px;
    height: 17px;
    path {
      fill: #ababab;
    }
  }
`;

export const BusinessContainer = styled.div`
  max-width: 1140px;
  margin: 42px auto 32px;

  ${mediaXlDown(css`
    max-width: 90%;
    margin-bottom: 23px;
  `)}
`;

export const LocationPhotos = styled.div`
  .ant-carousel {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
      height: 227px;
      display: inline-block;
      border-radius: 10px 10px 0 0;

      ${mediaMobileDown(css`
        height: 209px;
      `)}
    }
  }
`;
