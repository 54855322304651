import { useContext } from 'react';
import ReactIntl from '../components/ReactIntl';
import { getLabel, translateStaticTemplate } from '../helpers';

export default function useIntlOptions() {
  const { lang } = useContext(ReactIntl);
  const convertOptions = (options: any) =>
    (options || []).map((e: any) => {
      if (e.name?.includes?.('{')) {
        return {
          ...e,
          name: translateStaticTemplate(e.name, lang),
        };
      }
      return {
        ...e,
        name: getLabel(e.name, lang),
      };
    });
  return {
    convertOptions,
  };
}
