import React from 'react';
import { Button, CustomIcon, Text } from '@plandok/core';
import { MarketLocationService } from 'ghql';
import * as SC from './styles';

interface BookFlowFooterProps {
  values: any;
  isMobile: boolean;
  selectedServices: MarketLocationService[];
}

export const BookFlowFooter = ({ values, isMobile, selectedServices }: BookFlowFooterProps) => {
  const totalPrice = selectedServices?.reduce((acc, val) => acc + parseInt(val?.price?.amount), 0) || 0;
  const currency = values?.partner?.currency;

  return (
    <SC.BottomControl>
      {isMobile && (
        <div>
          <div className="align-center">
            <Text mb="none" size="small" weight="semiBold" colorType="link">
              {selectedServices?.length}
            </Text>
            <Text mb="none" size="small" colorType="darkBrown" ml="xxsmall" label="input.services.label" />
          </div>
          <Text mb="none" weight="semiBold" colorType="darkBrown">
            {totalPrice} {currency}
          </Text>
        </div>
      )}
      <Button
        type="primary"
        label="btn.next"
        loading={values.submitting}
        htmlType="submit"
        onClick={values.onNext}
        upperCase={false}
        disabled={!selectedServices?.length}
        bold
      >
        <CustomIcon type="arrowRightFooter" />
      </Button>
    </SC.BottomControl>
  );
};
