import StripePaymentFooter from 'pages/BookFlowPage/components/steps/PaymentStep/components/PaymentFooter/StripePaymentFooter';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Radio } from 'antd';
import { IntlTemplate } from '@plandok/i18n';
import {
  CustomIcon,
  Field,
  Text,
  composeValidators,
  validateEmail,
  validatePhone,
  validateRequired,
  PaymentType,
} from '@plandok/core';
import { useStore } from 'store';
import { PaymentFooter } from './components/PaymentFooter/PaymentFooter';
import { getAppointmentChangeTimeByCode } from './helpers';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import FlowStatusBlock from 'components/FlowStatusBlock';
import useMediaQuery from 'hooks/screen/use-media-query';
import { getLocationInfo } from 'api/axios/ip-api';
import { MarketLocationService } from 'ghql';
import * as SC from './styles';
import CardForm from './components/CardForm';

export function PaymentStep({ values }: any) {
  const [locationCallingCode, setLocationCallingCode] = useState<string | undefined>('');
  const [error, setError] = useState<any>({});

  const paymentType = useStore((state) => state.paymentType);
  const setPaymentType = useStore((state) => state.setPaymentType);

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const paymentTypes = values?.partner?.paymentTypes;
  const appointmentChangeTimeCode = values?.partner?.appointmentChangeTime;
  const importantInformation = values?.partner?.importantInformation;
  const onlineRequiredField = values?.partner?.onlineRequiredField;
  const serviceGroups = values?.serviceGroups;
  const serviceIds = values?.serviceIds;

  const appointmentChangeTime = getAppointmentChangeTimeByCode(appointmentChangeTimeCode);

  const selectedServices: MarketLocationService[] = [];
  const mappedServices: MarketLocationService[] = [];

  serviceGroups?.map(({ services }: { services: MarketLocationService[] }) => mappedServices.push(...services));

  serviceIds?.forEach((serviceId: string) => {
    const filteredServices = mappedServices?.filter(({ id }) => id === serviceId);

    selectedServices.push(...filteredServices);
  });

  const totalPrice = selectedServices?.reduce((acc, val) => acc + parseInt(val?.price.amount), 0);

  useEffect(() => {
    (async () => {
      if (!!totalPrice && paymentTypes.includes(PaymentType.STRIPE_PAYMENT)) {
        setPaymentType(PaymentType.STRIPE_PAYMENT);
      } else {
        setPaymentType(PaymentType.ON_SITE);
      }

      const { callingCode } = await getLocationInfo();

      setLocationCallingCode(callingCode || values.ownerPhone.split('-')[0]);
    })();
  }, [paymentTypes, setPaymentType, totalPrice, values.ownerPhone]);

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const checkRequiredField = (labelName: string) =>
    onlineRequiredField?.find((field: string) => field === labelName) ? '*' : '';

  return (
    <div className={isMobile ? 'justify-center' : 'justify-between'}>
      {!isMobile && <SC.Block />}

      <SC.ContentContainer>
        <Text
          size="base"
          colorType="darkBrown"
          label="market.ob.informationAboutYou.title"
          mb="small"
          weight="semiBold"
        />

        <SC.CardInformation>
          <Field.Row>
            <Field.Input
              name="firstName"
              label={<IntlTemplate label="market.ob.input.firstName.label" params={['*']} />}
              placeholder="market.ob.input.firstName.placeholder"
              md={11}
              validate={validateRequired}
              onKeyPress={onKeyPress}
              translate
            />
            <Field.Input
              name="lastName"
              label={<IntlTemplate label="market.ob.input.lastName.label" params={[checkRequiredField('LAST_NAME')]} />}
              placeholder="market.ob.input.lastName.placeholder"
              md={11}
              validate={checkRequiredField('LAST_NAME') && validateRequired}
              onKeyPress={onKeyPress}
              translate
            />
          </Field.Row>

          <Field.Input
            name="userEmail"
            label={<IntlTemplate label="market.ob.input.email.label" params={[checkRequiredField('EMAIL')]} />}
            placeholder="market.ob.input.email.placeholder"
            md={24}
            validate={checkRequiredField('EMAIL') ? composeValidators(validateRequired, validateEmail) : validateEmail}
            onKeyPress={onKeyPress}
            translate
          />
          <Field.CombinedInput
            name="phone"
            label={<IntlTemplate label="market.ob.input.phone.label" params={[checkRequiredField('PHONE_NUMBER')]} />}
            placeholder="market.ob.input.phone.placeholder"
            md={14}
            validate={
              checkRequiredField('PHONE_NUMBER') ? composeValidators(validateRequired, validatePhone) : validatePhone
            }
            translate
            preloadedPrefix={locationCallingCode}
            onKeyPress={onKeyPress}
          />

          <Field.TextArea
            name="notes"
            label="market.ob.input.description.label"
            placeholder="input.notes.label"
            onKeyPress={onKeyPress}
            rows={1}
          />

          <SC.FieldsRequiredTitle size="small" label="market.ob.fieldsAreRequired.title" mb="none" />
        </SC.CardInformation>

        {!!totalPrice && (
          <SC.PaymentMethodTitle>
            <Text size="base" colorType="darkBrown" label="market.ob.paymentMethod.title" mb="none" weight="semiBold" />

            <div className="align-center">
              <CustomIcon type="padlock" />
              <Text size="small" label="market.ob.securePayment.title" mb="none" weight="medium" ml="xxsmall" />
            </div>
          </SC.PaymentMethodTitle>
        )}

        {!!totalPrice && (
          <SC.CardPayment>
            {paymentTypes.includes(PaymentType.ON_SITE) && (
              <SC.CardPaySite
                onClick={() => setPaymentType(PaymentType.ON_SITE)}
                theme={{ selected: paymentType === PaymentType.ON_SITE }}
              >
                <Radio checked={paymentType === PaymentType.ON_SITE} />
                <Text
                  label="market.ob.payOnSite.title"
                  mb="none"
                  size="base"
                  weight="medium"
                  colorType="darkBrown"
                  ml="xxsmall"
                />
              </SC.CardPaySite>
            )}

            {paymentTypes.includes(PaymentType.STRIPE_PAYMENT) && (
              <SC.CardPayCard
                disabled={!!totalPrice}
                onClick={() => !!totalPrice && setPaymentType(PaymentType.STRIPE_PAYMENT)}
                theme={{ selected: paymentType === PaymentType.STRIPE_PAYMENT }}
              >
                <div className="d-flex">
                  <Radio checked={paymentType === PaymentType.STRIPE_PAYMENT} />
                  <Text
                    label="market.ob.payWithCard.title"
                    mb="none"
                    size="base"
                    weight="medium"
                    colorType="darkBrown"
                    ml="xxsmall"
                  />
                </div>
                <SC.WrapperIconsCards>
                  <div>
                    <CustomIcon type={isMobile ? 'maestroMobile' : 'maestroBooking'} />
                    <CustomIcon type={isMobile ? 'masterCardMobile' : 'masterCard'} />
                  </div>
                  <div>
                    <CustomIcon type={isMobile ? 'americanExpressMobile' : 'americanExpress'} />
                    <CustomIcon type={isMobile ? 'visaMobile' : 'visaBooking'} />
                  </div>
                </SC.WrapperIconsCards>
              </SC.CardPayCard>
            )}

            {paymentType === PaymentType.STRIPE_PAYMENT && <CardForm error={error} />}

            {paymentType === PaymentType.ON_SITE && (
              <SC.BlockPaySite>
                <Text
                  colorType="black"
                  label="market.ob.payOnSite.firstDescription"
                  size={isMobile ? 'small' : 'base'}
                  mb="xsmall"
                />
                <Text
                  colorType="black"
                  label="market.ob.payOnSite.secondDescription"
                  size={isMobile ? 'small' : 'base'}
                  mb="none"
                />
              </SC.BlockPaySite>
            )}
          </SC.CardPayment>
        )}

        {(importantInformation || appointmentChangeTime) && (
          <>
            <Text
              size="base"
              colorType="darkBrown"
              label={isMobile ? 'market.ob.information.title' : 'market.ob.additionalInformation.title'}
              mb="small"
              weight="semiBold"
            />

            <SC.CardAdditionalInformation>
              {importantInformation && (
                <SC.ImportantInfoContainer>
                  <Text mb="none" colorType="darkBrown" size="small">
                    {importantInformation}
                  </Text>
                </SC.ImportantInfoContainer>
              )}

              {appointmentChangeTime && (
                <>
                  <Text
                    colorType="black"
                    label="market.ob.reschedulingPolicy.title"
                    weight="semiBold"
                    size="small"
                    mb="xsmall"
                  />
                  <Text colorType="black" size="small" mb="none">
                    <IntlTemplate label="market.ob.reschedulingPolicy.description" params={[appointmentChangeTime]} />
                  </Text>
                </>
              )}
            </SC.CardAdditionalInformation>
          </>
        )}

        {paymentType === PaymentType.STRIPE_PAYMENT ? (
          <StripePaymentFooter totalPrice={totalPrice} values={values} isMobile={isMobile} setError={setError} />
        ) : (
          <PaymentFooter values={values} isMobile={isMobile} />
        )}
      </SC.ContentContainer>

      {!isMobile && <FlowStatusBlock values={values} />}
    </div>
  );
}
