import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { CustomIcon, Text, useHistory, useLocation, useParams } from '@plandok/core';
import { LocationCarousel } from '../LocationPage/components/LocationCarousel';
import { getMetaTagURL } from 'helpers/labels/app-meta-url';
import InfinitySpinner from 'components/InfinitySpinner';
import { BusinessInfo } from './components/BusinessInfo';
import { MarketPartnerResponse, query } from 'ghql';
import MetaData from 'components/MetaData';
import { ReactIntl } from '@plandok/i18n';
import * as SC from './styles';

export default function PartnerPage() {
  const history = useHistory();
  const { lang } = useContext(ReactIntl);
  const { location } = useLocation<any>();
  const params: any = useParams();

  const partnerSlug = params?.slug;
  const context = { language: lang };

  const { data: partnerData, loading: partnerLoading } = useQuery<MarketPartnerResponse>(query.MARKET_PARTNER, {
    variables: { context, slug: partnerSlug },
  });

  const partner = partnerData?.partner;
  const selectedPartnerData = location?.state;

  const bookNow = (locationId: string) => {
    const findLocation = partner?.locations?.find(({ id }) => id === locationId);

    const slug = findLocation?.slug;
    const navigateUrl = params.locale ? `/${lang}/${slug}` : `/${slug}`;

    if (findLocation) {
      history.push(navigateUrl);
    }
  };

  const locationName = location?.name ? `${location?.name} - ` : '';
  const locationAddress = location?.address?.address ? `${location?.address?.address} - ` : '';
  const locationCity = location?.address?.city ? `${location?.address?.city} | Plandok` : '';
  const metaTitle = `${locationName}${locationAddress}${locationCity}`;

  return (
    <>
      {partnerLoading ? (
        <InfinitySpinner />
      ) : (
        <>
          {location && <MetaData title={metaTitle} location={location} url={getMetaTagURL(params, partnerSlug)} />}

          {!selectedPartnerData && (
            <>
              {partner && <BusinessInfo partner={partner} />}

              <SC.Container theme={{ count: partner?.locations?.length }}>
                {!!partner?.locations?.length &&
                  partner.locations.map((location) => (
                    <div onClick={() => bookNow(location.id)} className="cursor-pointer" key={location.id}>
                      <SC.ContainerCard noPadding>
                        {!!location?.photos?.length && (
                          <SC.LocationPhotos>
                            <LocationCarousel photos={[location?.photos[0]]} buttons={false} dots={false} />
                          </SC.LocationPhotos>
                        )}
                        <SC.ContainerAddress>
                          <Text size="large" mb="xsmall" weight="medium" colorType="black">
                            {location?.name}
                          </Text>
                          <SC.WrapperAddress>
                            <CustomIcon type="locationSmall" />
                            <Text size="base" mb="none" ml="xsmall">
                              {location?.address?.address ? `${location?.address?.address}, ` : ''}
                              {location?.address?.city ? location?.address?.city : ''}
                            </Text>
                          </SC.WrapperAddress>
                        </SC.ContainerAddress>
                      </SC.ContainerCard>
                    </div>
                  ))}
              </SC.Container>
            </>
          )}
        </>
      )}
    </>
  );
}
