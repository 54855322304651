import styled from 'styled-components';
import { Card } from '@plandok/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonsWithTimeContainer = styled.div`
  max-height: calc(100vh - ${({ theme }) => (theme.allowEmployeeSelection ? '555px' : '398px')});
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #e9ecf3;
  }
  &::-webkit-scrollbar-thumb {
    background: #adb6cc;
    border-radius: 10px;
  }

  button {
    display: block;
    width: 100%;
    border-radius: initial;
    padding: 13px 58px;
    min-height: 48px;
    background: #ffffff;
    :hover,
    :focus {
      background: #f4f7ff;
    }
    :not(:last-of-type) {
      border-bottom: 1px solid #e9ebef;
    }
  }

  @media screen and (max-width: 1024px) {
    max-height: calc(100vh - ${({ theme }) => (theme.allowEmployeeSelection ? '468px' : '319px')});

    button {
      padding: 14px 20px 13px 16px;
    }
  }
`;

export const FullyBookedContent = styled.div`
  text-align: center;
  margin-top: 12px;

  div:first-child {
    margin: 0 auto 16px;
    max-width: 250px;
  }

  div:last-child {
    margin-bottom: 24px;
  }
`;

export const CustomCard = styled(Card)`
  padding: 0;
  border: none;

  @media screen and (max-width: 1024px) {
    box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
    border-radius: 5px;
    padding: 0 0 8px;

    .react-datepicker__input-container {
      margin: 16px 16px 12px;
      text-align: center;
    }
  }
`;
