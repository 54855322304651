import { Card } from '@plandok/core';
import styled from 'styled-components';
import { Select } from 'antd';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #f4f5f6;
  min-height: 100vh;
`;

export const BodyContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto ${({ theme }) => (theme.step === 2 ? '42px' : '122px')};

  ${({ theme }) => {
    if (theme.isMobile && theme.step === 2)
      return `
        margin-top: 88px;
      `;
  }}
`;

export const Block = styled.div`
  width: 295px;
`;

export const ContentWrapper = styled.div`
  padding-bottom: ${({ theme }) => (theme.isMobile ? 'unset' : '200px')};
`;

export const StaffSelect = styled(Select)`
  width: 480px !important;
  margin-bottom: 48px;

  .ant-select-selection {
    height: 72px;
    box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
    border-radius: 10px;
    border: none !important;
    z-index: ${({ theme }) => (theme.overlay ? '7' : '4')};
    :focus {
      border: 1px solid #225ffe;
      box-shadow: 0px 0px 6px rgba(34, 95, 254, 0.7);
    }
    .ant-select-selection__rendered {
      line-height: 55px;
      margin: 0 32px;
      svg {
        width: 48px;
        height: 48px;
      }
      svg + div,
      .ant-avatar + div {
        font-size: 16px;
      }
      .ant-avatar {
        width: 48px !important;
        height: 48px !important;
        .ant-avatar-string {
          line-height: 48px !important;
          font-size: 20px;
        }
      }
      .ant-select-selection-selected-value > div {
        padding: 7px 0;
      }
    }
    .ant-select-arrow {
      right: 30px;
      i {
        svg {
          fill: #000000;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: calc(100vw - 40px) !important;
    margin-bottom: 24px;

    .ant-select-selection {
      :focus {
        border: none;
        box-shadow: none;
      }
    }
  }
`;

export const PickDateAndTime = styled(Card)`
  width: 480px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
  padding: 0 0 8px;

  @media screen and (max-width: 1024px) {
    width: calc(100vw - 40px);
    background: inherit;
    box-shadow: none;
  }
`;

export const CustomOverlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.52);
  z-index: 6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
