import { mediaMdDown, mediaMdUp, mediaSmDown } from '@plandok/core';
import styled, { css } from 'styled-components';
import { Carousel } from 'antd';

export const CustomCarousel = styled(Carousel)`
  ${mediaMdUp(css`
    .slick-list .slick-slide.slick-active {
      width: ${({ theme }) => theme.specialWidth && '380px !important'};
  `)}
}
`;

export const Img = styled.img`
  width: 364px !important;
  height: 297px;
  border-radius: 10px;
  object-fit: cover;

  ${mediaMdDown(css`
    width: 178px !important;
    height: 145px;
  `)}

  ${mediaSmDown(css`
    width: 165px !important;
  `)}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin: 0 16px 16px;

  button {
    width: 40px;
    height: 40px;
    svg {
      path {
        stroke: #777e90;
      }
    }
  }
  button:first-of-type {
    margin-right: 3px;
  }
  button:last-of-type {
    margin-left: 3px;
    svg {
      transform: rotate(180deg);
    }
  }
`;
