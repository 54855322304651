import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import format from 'date-fns/format';
import {
  Button,
  CustomIcon,
  formatTime,
  IconButton,
  removeTimeZone,
  Text,
  useLocation,
  useModal,
  useParams,
} from '@plandok/core';
import { LanguageFnsConfig, ReactIntl } from '@plandok/i18n';
import { MarketAppointmentBookings, MarketAppointmentResponse, mutation, query } from 'ghql';
import AppointmentCancelledVariables from 'components/AppointmentCancelledVariables';
import InfinitySpinner from 'components/InfinitySpinner';
import LocationAddress from 'components/LocationAddress';
import useMediaQuery from 'hooks/screen/use-media-query';
import { SMALL_BREAKPOINT } from 'constants/breakpoints';
import * as SC from './styles';

export default function AppointmentPage() {
  const [isShowCancelButton, setIsShowCancelButton] = useState(true);

  const isSmall = useMediaQuery(`(max-width: ${SMALL_BREAKPOINT}px)`);
  const { lang } = useContext(ReactIntl);
  const [, { showModal }] = useModal();
  const { navigate } = useLocation();
  const params: any = useParams();

  const context = { language: lang };

  const {
    data: appointmentData,
    loading: appointmentLoading,
    refetch,
  } = useQuery<MarketAppointmentResponse>(query.MARKET_APPOINTMENT, {
    variables: { context, id: params?.id, entryToken: params?.entryToken },
  });

  const [cancelAppointment, { error }] = useMutation(mutation.CANCEL_APPOINTMENT, {
    onError: () => {},
    errorPolicy: 'all',
  });

  const appointment = appointmentData?.appointment;
  const location = appointment?.location;
  const bookings = (appointment?.bookings ?? []) as MarketAppointmentBookings[];
  const phone = location?.phone?.replaceAll('-', '');
  const appointmentIsCancelled = appointment?.status === 'CANCELLED';
  const timeFormat = appointment?.partner.timeFormat;
  const isCancellable = appointment?.isCancellable;
  const currency = appointment?.partner.currency;
  const appointmentId = appointment?.id ?? '';
  const address = location?.address;
  const photos = location?.photos;
  const date = appointment?.date;
  const email = location?.email;
  const slug = location?.slug;

  const bookDate = date && format(removeTimeZone(new Date(date)), 'EEEE, dd MMM', { locale: LanguageFnsConfig[lang] });
  const totalPrice = bookings?.reduce(
    (acc, val) => acc + parseInt((val?.service && val.service.price.amount) ?? ''),
    0
  );
  const assignedSpecialistFirstName = !!bookings?.length && bookings[0]!.employee?.firstName;
  const assignedSpecialistLastName = !!bookings?.length && bookings[0]!.employee?.lastName;
  const bookTime = !!bookings?.length && formatTime(bookings[0]!.startTime, timeFormat);
  const navigateToLocationUrl = params?.locale ? `/${lang}/${slug}` : `/${slug}`;
  const mutationError = (error as any)?.toString().split(':').reverse()[0];

  const openCancelAppointment = () =>
    showModal({
      type: 'CANCEL_APPOINTMENT',
      modalProps: {
        setIsShowCancelButton,
        context,
        appointmentId,
        entryToken: params?.entryToken,
        cancelAppointment,
        mutationError,
        refetch,
      },
    });

  const goToLocationPage = () => navigate(navigateToLocationUrl);

  return (
    <>
      {appointmentLoading ? (
        <InfinitySpinner />
      ) : (
        <SC.PageContainer>
          {!isSmall && (
            <SC.HeaderContainer>
              <IconButton icon="close" onClick={goToLocationPage} size="40px" />
            </SC.HeaderContainer>
          )}

          <SC.CardAppointment>
            <SC.CalendarIconContainer>
              <CustomIcon
                type={appointmentIsCancelled ? 'cancelledAppointmentCalendar' : 'createAppointmentCalendar'}
              />
            </SC.CalendarIconContainer>

            {!appointmentIsCancelled && (
              <Text
                label="market.confirmation.appointmentConfirmed.title"
                colorType="black"
                weight="semiBold"
                size={isSmall ? 'large' : 'mlarge'}
                mb="xsmall"
              />
            )}
            <Text
              label={
                appointmentIsCancelled
                  ? 'market.confirmation.appointmentCanceled.title'
                  : 'market.confirmation.appointmentConfirmed.description'
              }
              colorType="black"
              weight="semiBold"
              size={isSmall ? 'large' : 'mlarge'}
              mb="medium"
            />

            <SC.DetailsAppointment>
              <SC.AppointmentDateContainer>
                {!isSmall && (
                  <SC.AppointmentDateTitle mb="none" size="small" label="market.confirmation.appointmentDate.title" />
                )}
                <Text mb="small" size={isSmall ? 'large' : 'base'} colorType="black" weight="medium">
                  {bookDate}
                </Text>

                {bookTime && (
                  <SC.TimeContainer>
                    <div className="d-flex">
                      <CustomIcon type="locationTime" />
                    </div>
                    <Text mb="none" size="base" ml="small" colorType="black">
                      {bookTime}
                    </Text>
                  </SC.TimeContainer>
                )}

                {address && <LocationAddress address={address} />}

                {(assignedSpecialistFirstName || assignedSpecialistLastName) && (
                  <SC.AssignEmployeeContainer>
                    <div className="d-flex">
                      <CustomIcon type="assignedEmployee" />
                    </div>
                    <Text mb="none" size="base" ml="small" colorType="black">
                      {assignedSpecialistFirstName || ''} {assignedSpecialistLastName || ''}
                    </Text>
                  </SC.AssignEmployeeContainer>
                )}
              </SC.AppointmentDateContainer>

              {!!photos?.length && (
                <SC.ImageContainer theme={{ background: photos[0] }}>
                  {isSmall && (
                    <Text mb="none" colorType="white" size="small" weight="semiBold">
                      {location?.name}
                    </Text>
                  )}
                </SC.ImageContainer>
              )}
            </SC.DetailsAppointment>

            <SC.ServicesContainer>
              {!!bookings?.length &&
                bookings.map(({ id, service }) => (
                  <SC.ServiceContainer key={id}>
                    <div className="justify-between">
                      {service?.name && (
                        <Text mb="none" size="base" colorType="black">
                          {service.name}
                        </Text>
                      )}
                      {service?.price?.amount && !!parseInt(service?.price?.amount) && (
                        <Text mb="none" size="base" colorType="black" weight="medium">
                          {service.price.amount} {currency}
                        </Text>
                      )}
                    </div>
                    <div className="d-flex">
                      <Text size="small" mb="none">
                        {service?.duration}
                      </Text>
                      <Text size="small" mb="none" label="market.ob.service.duration.title" />
                    </div>
                  </SC.ServiceContainer>
                ))}
            </SC.ServicesContainer>

            {!appointmentIsCancelled && (
              <SC.TotalInfo>
                <Text mb="none" label="appointment.total" weight="medium" colorType="black" />
                <Text mb="none" weight="medium" colorType="black">
                  {totalPrice} {currency}
                </Text>
              </SC.TotalInfo>
            )}

            {isShowCancelButton && !appointmentIsCancelled && !mutationError && isCancellable ? (
              <SC.ButtonsContainer>
                <Button
                  label="market.btn.cancelAppointment"
                  upperCase={false}
                  bold
                  block
                  onClick={openCancelAppointment}
                />
                <Button whiteBtn label="btn.close" onClick={goToLocationPage} upperCase={false} bold block />
              </SC.ButtonsContainer>
            ) : (
              <AppointmentCancelledVariables
                appointmentIsCancelled={appointmentIsCancelled}
                isSmall={isSmall}
                goToLocationPage={goToLocationPage}
                email={email}
                phone={phone}
              />
            )}
          </SC.CardAppointment>

          {!isSmall && <SC.FooterContainer />}
        </SC.PageContainer>
      )}
    </>
  );
}
