import { Button, IconButton, Text, useLocation } from '@plandok/core';
import React, { useRef, useState } from 'react';
import { scrollHandler, scrollLeft, scrollRight, setActiveTitlePosition } from './helpersForScrolling';
import useScrollingServices from 'hooks/use-scrolling-services';
import SelectServiceRow from 'components/SelectServiceRow';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import useMediaQuery from 'hooks/screen/use-media-query';
import { CategoryItem } from 'pages/BookFlowPage/types';
import { MarketLocationService } from 'ghql';
import * as SC from './styles';
import './style.less';

type BookFlowServicesProps = {
  categories: CategoryItem[];
  selectedServices: string[];
  bookingLimit: number;
};

const HEIGHT_TITLES_BLOCK = 66;

export const BookFlowServices = ({ categories, selectedServices, bookingLimit }: BookFlowServicesProps) => {
  const { location } = useLocation();
  const [viewService, setViewService] = useState<string>(location.state?.activeTitle || categories[0].title);

  const titlesContainer = useRef<HTMLDivElement>(null);
  const leftArrow = useRef<HTMLDivElement>(null);
  const rightArrow = useRef<HTMLDivElement>(null);

  const description = categories.find(({ title }) => title === viewService)?.description;
  const services = categories.find(({ title }) => title === viewService)?.services;

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const activeServiceBlock = {
    id: location.state?.activeTitle || 0,
    position: 0,
  };

  useScrollingServices(leftArrow, scrollHandler, isMobile, location, HEIGHT_TITLES_BLOCK);

  document?.querySelectorAll('.blocks > *').forEach((elem: Element) => {
    const headerBottom = document?.querySelector('.titles');

    if (headerBottom) {
      const position =
        elem?.getBoundingClientRect().top - headerBottom.getBoundingClientRect().bottom + HEIGHT_TITLES_BLOCK;

      if (position < HEIGHT_TITLES_BLOCK) {
        activeServiceBlock.id = Number((elem as HTMLElement).dataset.id);
        activeServiceBlock.position = position;
      }
    }
  });

  document?.querySelector(`.titles > * > .active`)?.classList.remove('active');
  document?.querySelector(`.titles > * > [data-id="${activeServiceBlock.id}"]`)?.classList.add('active');

  const timeoutId = window.setTimeout(() => setActiveTitlePosition(timeoutId), 50);

  document?.querySelector('.titles')?.addEventListener('click', (event: any) => {
    event.preventDefault();
    const id = event.target?.dataset.id;

    if (id) {
      document?.querySelector(`.titles > * > .active`)?.classList.remove('active');
      document?.querySelector(`.titles > * > [data-id="${id}"]`)?.classList.add('active');

      const pagePosition = window.pageYOffset;
      const activeTopPosition: number | undefined = document
        ?.querySelector(`.blocks [data-id="${id}"]`)
        ?.getBoundingClientRect().top;
      const titlesElem: HTMLDivElement | null = document?.querySelector('.titles');
      const titlesBottomLinePosition: number | null =
        titlesElem &&
        titlesElem?.getBoundingClientRect().bottom -
          (titlesElem?.getBoundingClientRect().bottom - titlesElem.clientHeight);

      if (activeTopPosition && titlesBottomLinePosition) {
        window?.scrollTo({
          top: activeTopPosition + pagePosition - (titlesBottomLinePosition + HEIGHT_TITLES_BLOCK),
          behavior: 'smooth',
        });
      }
    }
  });

  const setActiveService = (title: string) => setViewService(title);

  return (
    <>
      {isMobile ? (
        <>
          <div
            className="scrolling-right"
            onClick={(event) => scrollRight(event, titlesContainer, leftArrow, rightArrow)}
            ref={leftArrow}
          >
            <IconButton icon="arrowSlider" />
          </div>

          <div className="titles">
            <div ref={titlesContainer}>
              {categories.map((item, idx) => (
                <Button data-id={`${idx}`} key={idx} upperCase={false}>
                  {item.title} {item.services.length}
                </Button>
              ))}
            </div>
          </div>

          <div
            className="scrolling-left"
            onClick={(event) => scrollLeft(event, titlesContainer, leftArrow, rightArrow)}
            ref={rightArrow}
          >
            <IconButton icon="arrowSlider" />
          </div>

          <div className="blocks">
            {categories?.map((item: CategoryItem, idx) => (
              <div data-id={`${idx}`} key={idx}>
                {item.title && <div className="title">{item.title}</div>}

                {item.description && <div className="description">{item.description}</div>}

                {item.services.map((service: MarketLocationService) => (
                  <SelectServiceRow
                    key={service.id}
                    currentService={service}
                    allServices={item.services}
                    selectedServices={selectedServices}
                    bookingLimit={bookingLimit}
                  />
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
        <SC.ServicesWrapper>
          <SC.ButtonsContainer>
            {categories?.map((item: CategoryItem) => (
              <SC.ButtonService
                theme={{ selected: item.title === viewService }}
                onClick={() => setActiveService(item.title)}
                key={item.title}
              >
                <Text mb="none" size={isMobile ? 'small' : 'base'} weight="semiBold" className="align-center">
                  <div className="mr-sm">{item.title}</div>
                  {item.services.length}
                </Text>
              </SC.ButtonService>
            ))}
          </SC.ButtonsContainer>

          <SC.ServicesBlock>
            {description && (
              <Text mb="mlarge" size="base" colorType="brown">
                {description}
              </Text>
            )}

            {!!services?.length &&
              services.map((item: MarketLocationService) => (
                <SelectServiceRow
                  key={item.id}
                  currentService={item}
                  allServices={services}
                  selectedServices={selectedServices}
                  bookingLimit={bookingLimit}
                />
              ))}
          </SC.ServicesBlock>
        </SC.ServicesWrapper>
      )}
    </>
  );
};
