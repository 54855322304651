import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CustomIcon } from '@plandok/core';
import { WrapperAddress } from './WrapperAddress';
import { MarketLocation } from 'ghql';
import * as SC from './styles';

type LocationAddressProps = {
  location: MarketLocation | null | undefined;
  isMobile: boolean;
  isBookNow?: boolean;
  isTitle?: boolean;
  isAddress?: boolean;
  isWorkingHours?: boolean;
  isPhone?: boolean;
  bookNow?: () => void;
  isVisibleHeaderAndFooter: boolean;
  heightToButton: number;
  setHeightToButton: Dispatch<SetStateAction<number>>;
  timeFormat: string;
};

export const LocationAddress = ({
  location,
  isMobile,
  isAddress,
  isBookNow,
  isTitle,
  isWorkingHours,
  isPhone,
  bookNow,
  isVisibleHeaderAndFooter,
  heightToButton,
  setHeightToButton,
  timeFormat,
}: LocationAddressProps) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const heightTitle = useRef<HTMLInputElement>(null);
  const heightToBookNow = document.querySelector('#book-now')?.getBoundingClientRect().top;

  useEffect(() => {
    setHeight(heightTitle?.current?.clientHeight);

    if (!heightToButton && heightToBookNow) {
      setHeightToButton(heightToBookNow);
    }
  }, [heightToButton, heightToBookNow, setHeightToButton]);

  const getTitleLongestWord = (title: string) => {
    return title.split(' ').reduce((a, b) => {
      return a.length > b.length ? a : b;
    }, '').length;
  };

  return (
    <SC.ContainerLocation theme={{ isPhone }}>
      <div className="w-100">
        {isTitle && location?.name && (
          <SC.Title
            weight="semiBold"
            theme={{
              height,
              lengthOfLongestWord: getTitleLongestWord(location?.name),
            }}
            colorType="black"
          >
            <div ref={heightTitle}>{location?.name}</div>
          </SC.Title>
        )}
        {isPhone && (
          <>
            {location?.email && (
              <SC.InfoItem>
                <CustomIcon type="emailLocation" />
                <a href={`mailto:${location?.email}`}>{location?.email}</a>
              </SC.InfoItem>
            )}
            {location?.phone && (
              <SC.InfoItem>
                <CustomIcon type="phoneLocation" />
                <a href={`tel:${location?.phone?.replaceAll('-', '')}`}>{location?.phone}</a>
              </SC.InfoItem>
            )}
          </>
        )}

        <WrapperAddress
          location={location}
          isPhone={isPhone}
          isMobile={isMobile}
          isWorkingHours={isWorkingHours}
          isBookNow={isBookNow}
          isVisibleHeaderAndFooter={isVisibleHeaderAndFooter}
          bookNow={bookNow}
          isAddress={isAddress}
          timeFormat={timeFormat}
        />
      </div>
    </SC.ContainerLocation>
  );
};
