import styled from 'styled-components';

export const ContainerHeader = styled.header`
  position: fixed;
  background-color: #fcfcfd;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
`;

export const ContainerButtons = styled.div`
  max-width: 500px; 
  margin: 0 auto;
  display: flex; 
  justify-content: space-evenly;
  padding: 40px 0;
  button {
    padding: 0 30px;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    background-color: inherit;
    box-shadow: none;
    &:hover,
    &:active,
    &:focus {
      color: #000000;
    }
    :first-of-type {
      border: 1px solid #7F8596;
    }
    :last-of-type {
      display: flex;
      align-items: center;
      }
    }
  }
`;
