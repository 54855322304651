import pick from 'lodash/pick';
import { PaymentStep } from 'pages/BookFlowPage/components/steps/PaymentStep';
import StripeBookingFlowStep from 'pages/BookFlowPage/StripeBookingFlowStep';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery } from '@apollo/client';
import { message } from 'antd';
import { PaymentType, removeEmojiFromString, useHistory, useLocation, useParams } from '@plandok/core';
import { IntlLabel, ReactIntl } from '@plandok/i18n';
import { MarketLocationResponse, mutate, mutation, query } from 'ghql';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import FlowStatusBlock from 'components/FlowStatusBlock';
import InfinitySpinner from 'components/InfinitySpinner';
import useMediaQuery from 'hooks/screen/use-media-query';
import { useStore } from 'store';
import StaffAndDateSelectStep from './components/steps/StaffAndDateSelectStep';
import ServiceSelectStep from './components/steps/ServiceSelectStep';
import { BookingFlowForm, BookingFlowStepType } from './types';
import BookingFlowStep from './BookingFlowStep';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as SC from './styles';

export default function BookFlowPage() {
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [step, setStep] = useState(0);

  const { lang } = useContext(ReactIntl);
  const { location } = useLocation<any>();
  const history = useHistory();

  const params: any = useParams();

  const setEmployeeId = useStore((state) => state.setEmployeeId);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const context = { language: lang };

  const slug = params?.slug;

  const locationState = location?.state;
  const preselectedServiceId = locationState?.id;

  const employeeId = useStore((state) => state.employeeId);
  const paymentMethod = useStore((state) => state.paymentMethod);
  const paymentType = useStore((state) => state.paymentType);

  const paymentTypes = initialValues?.partner.paymentTypes;
  const paymentSecret = initialValues?.partner.paymentSecret;
  const paymentAccountId = initialValues?.partner.paymentAccountId;
  const isAllowEmployeeSelection = initialValues?.partner.allowEmployeeSelection;
  const navigateToLocationUrl = params?.locale ? `/${lang}/${slug}` : `/${slug}`;
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const { data: locationData, loading: locationLoading } = useQuery<MarketLocationResponse>(query.MARKET_LOCATION, {
    variables: { context, slug },
  });

  const isLastStep = isMobile ? step === 3 : step === 2;

  useEffect(() => {
    if (locationData && !initialValues) {
      const newLocationData = { ...locationData?.location, phone: '' } as any;
      const phone = locationData?.location?.phone || null;

      setInitialValues(
        preselectedServiceId
          ? { serviceIds: [preselectedServiceId], ...newLocationData, ownerPhone: phone }
          : { ...newLocationData, ownerPhone: phone }
      );
    }
    setEmployeeId(null);
  }, [locationData, locationState, preselectedServiceId, setEmployeeId, initialValues]);

  const [stripePromise, setStripePromise] = useState<any | undefined>();

  useEffect(() => {
    if (paymentTypes?.includes(PaymentType.STRIPE_PAYMENT)) {
      setStripePromise(
        loadStripe(paymentSecret, {
          stripeAccount: paymentAccountId,
        })
      );
    }
  }, [paymentAccountId, paymentSecret, paymentTypes]);

  const steps: BookingFlowStepType[] = [
    {
      title: 'market.ob.services.step.title',
      component: ServiceSelectStep,
    },
    {
      title: isAllowEmployeeSelection ? 'market.ob.employee.step.title' : 'market.ob.timeSlot.step.title',
      component: StaffAndDateSelectStep,
    },
    {
      title: 'market.ob.personalInfo.step.title',
      component: PaymentStep,
    },
    {
      title: 'market.ob.confirm.step.title',
      component: FlowStatusBlock,
    },
  ];

  const stepConfig = steps[step];

  const onSuccess = () => {
    if (!isLastStep && !isBackClicked) {
      setStep(step + 1);
    }
  };

  const onSetStep = () => {
    if (!isBackClicked) {
      setStep(step + 1);
    }
  };

  const onNext = (response: any) => {
    const createAppointment = response?.data?.createAppointment;

    const errors = response?.errors;
    const validationError = response?.errors?.error;

    const success = createAppointment?.success;

    const appointmentId = createAppointment?.appointmentId ?? '';
    const entryToken = createAppointment?.entryToken ?? '';

    const navigateToAppointmentPage = params?.locale
      ? `/${lang}/appointment/${appointmentId}/${entryToken}`
      : `/appointment/${appointmentId}/${entryToken}`;

    if (createAppointment && success && !errors) {
      message.success(<IntlLabel label="market.ob.success.message" />);
      history.push(navigateToAppointmentPage, { locale: params?.locale });
    }

    if (validationError) {
      message.error(<IntlLabel label={validationError} />);
    }

    if (errors) {
      message.error(<IntlLabel label={errors?.['FINAL_FORM/form-error'] || errors[0].message} />);
    }
  };

  const onAppointmentValidate = async (form: BookingFlowForm) => {
    const token = await recaptchaRef?.current?.executeAsync();

    let updatedForm = {
      ...form,
      locationId: initialValues.id,
      email: form.userEmail ? form.userEmail.trim() : null,
      phone: form.phone ? removeEmojiFromString(form.phone) : null,
      employeeId,
      paymentType: paymentType,
      paymentMethod: paymentMethod,
      recaptcha: token,
    };

    recaptchaRef?.current?.reset();

    return await mutate(
      mutation.CREATE_APPOINTMENT,
      true
    )({
      context,
      input: pick(
        updatedForm,
        'firstName',
        'lastName',
        'email',
        'phone',
        'locationId',
        'employeeId',
        'datetime',
        'notes',
        'serviceIds',
        'paymentType',
        'paymentMethod',
        'recaptcha'
      ),
    });
  };

  const onClose = () => history.push(navigateToLocationUrl);

  const onBack = () => {
    if (!step) {
      return onClose();
    }
    setStep(step - 1);
    setIsBackClicked(true);
  };

  return (
    <>
      {locationLoading ? (
        <InfinitySpinner />
      ) : (
        <>
          <SC.PageContainer>
            {stripePromise && paymentTypes?.includes(PaymentType.STRIPE_PAYMENT) ? (
              <Elements stripe={stripePromise}>
                <StripeBookingFlowStep
                  initialValues={initialValues || {}}
                  step={step}
                  stepConfig={stepConfig}
                  onBack={onBack}
                  onNext={onNext}
                  onSuccess={onSuccess}
                  onSetStep={onSetStep}
                  onClose={onClose}
                  isLastStep={isLastStep}
                  recaptchaRef={recaptchaRef}
                  setIsBackClicked={setIsBackClicked}
                  onAppointmentValidate={onAppointmentValidate}
                />
              </Elements>
            ) : (
              <BookingFlowStep
                initialValues={initialValues || {}}
                step={step}
                stepConfig={stepConfig}
                onBack={onBack}
                onNext={onNext}
                onSuccess={onSuccess}
                onSetStep={onSetStep}
                onClose={onClose}
                isLastStep={isLastStep}
                recaptchaRef={recaptchaRef}
                setIsBackClicked={setIsBackClicked}
                onAppointmentValidate={onAppointmentValidate}
              />
            )}
            {isLastStep && (
              <ReCAPTCHA
                sitekey={'6LengtMZAAAAABu13Z5q1Cgt_uq_hTHyYSqKaZZi' || ''}
                size="invisible"
                ref={recaptchaRef}
              />
            )}
          </SC.PageContainer>
        </>
      )}
    </>
  );
}
