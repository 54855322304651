import { Button, CustomIcon, Text } from '@plandok/core';
import { MarketLocation } from 'ghql';
import React from 'react';
import { LocationWorkingHours } from '../LocationWorkingHours';
import * as SC from './styles';

type WrapperAddressProps = {
  location: MarketLocation | null | undefined;
  isPhone?: boolean;
  isMobile: boolean;
  isWorkingHours?: boolean;
  isBookNow?: boolean;
  isVisibleHeaderAndFooter: boolean;
  bookNow?: () => void;
  isAddress?: boolean;
  timeFormat: string;
};

export const WrapperAddress = ({
  location,
  isPhone,
  isMobile,
  isWorkingHours,
  isBookNow,
  isVisibleHeaderAndFooter,
  bookNow,
  isAddress,
  timeFormat,
}: WrapperAddressProps) => {
  const { address, zipCode, city, state, details } = location?.address || {};
  const addressValue = `${address || ''}, ${city || ''}, ${zipCode || ''}`;
  const addressShown = isAddress && details;

  const GetDirectionsForm = () => (
    <form action="https://maps.google.com/maps" method="get" target="_blank">
      <input type="hidden" name="daddr" value={addressValue} />
      <input type="hidden" name="saddr" />

      <SC.ButtonGetDirection htmlType="submit" theme={{ isPhone }}>
        <Text
          size={isPhone ? 'small' : isMobile ? 'base' : 'small'}
          mb="none"
          label="market.location.getDirections.title"
          weight={isPhone ? 'semiBold' : 'normal'}
        />
        <CustomIcon type={isPhone ? 'pointer' : 'smallPointer'} />
      </SC.ButtonGetDirection>
    </form>
  );

  return (
    <SC.WrapperAddress>
      {(address || zipCode || city || state) && (
        <SC.LocationAddress theme={{ isPhone }}>
          <div>
            <CustomIcon type={isPhone ? 'locationSmall' : 'location'} />
          </div>
          <Text size={isPhone ? 'small' : isMobile ? 'base' : 'small'} mb="none" ml="small" colorType="darkBrown">
            {address && address}
            {zipCode && `, ${zipCode}`}
            {city && `, ${city}`}
            {state && `, ${state}`}
            <GetDirectionsForm />
          </Text>
        </SC.LocationAddress>
      )}
      {isWorkingHours && (
        <LocationWorkingHours
          workingHours={location?.workingHours}
          oneDay
          isMobile={isMobile}
          timeFormat={timeFormat}
        />
      )}
      {isBookNow && ((!isVisibleHeaderAndFooter && isMobile) || !isMobile) && (
        <Button
          type="primary"
          data-book-now
          label="market.btn.bookNow"
          onClick={bookNow}
          upperCase={false}
          id="book-now"
        />
      )}
      {addressShown && (
        <SC.LocationAddressDetails>
          <Text size="small" mb="none" colorType="darkBrown">
            {details}
          </Text>
        </SC.LocationAddressDetails>
      )}
    </SC.WrapperAddress>
  );
};
