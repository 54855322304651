import React from 'react';
import { MOBILE_BREAKPOINT } from '../../../../../../constants/breakpoints';
import useMediaQuery from '../../../../../../hooks/screen/use-media-query';
import { MarketEmployee } from '../../../../../../ghql';
import { CustomIcon, Text } from '@plandok/core';
import * as SC from './styles';

interface DropdownStaffItemProps {
  employee?: MarketEmployee;
  isNoPreference?: boolean;
}

export default function DropdownStaffItem({ employee, isNoPreference }: DropdownStaffItemProps) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  return (
    <>
      {isNoPreference ? (
        <SC.StaffItem>
          <SC.CustomAvatar>
            <CustomIcon type="noPreferenceUser" />
            <Text label="market.ob.staff.noPreference.label" mb="none" size="small" colorType="darkBrown" ml="base" />
          </SC.CustomAvatar>
        </SC.StaffItem>
      ) : (
        <SC.StaffItem>
          <div className="align-center">
            <SC.StyledAvatar
              src={employee?.photo ? '//assets.plandok.com/staff-photos/' + employee?.photo : undefined}
              size={isMobile ? 40 : 32}
            >
              {employee?.firstName ? employee.firstName[0] : ''}
              {employee?.lastName ? employee.lastName[0] : ''}
            </SC.StyledAvatar>
            <Text mb="none" size="small" colorType="darkBrown" ml="base">
              {employee?.firstName || ''} {employee?.lastName || ''}
            </Text>
          </div>
          <CustomIcon type="checkmark" />
        </SC.StaffItem>
      )}
    </>
  );
}
