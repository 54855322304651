import styled from 'styled-components';
import { Button } from '@plandok/core';

export const ServicesWrapper = styled.div`
  display: flex;
  button:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-right: 15px;
  max-width: 240px;
`;

export const ServicesBlock = styled.div`
  width: 566px;
`;

export const ButtonService = styled(Button)`
  display: block;
  box-shadow: none !important;
  border: none;
  background: ${({ theme }) => (theme.selected ? '#353945' : '#f4f5f6')};
  min-height: 34px;
  white-space: normal;
  height: max-content;
  padding: 5px 15px;
  text-align: start;

  div {
    transition: all 0.3s;
    color: ${({ theme }) => (theme.selected ? '#ffffff' : '#777e90')};
    line-height: 24px;
    font-weight: ${({ theme }) => (theme.selected ? '600' : '400')};
  }
  :hover,
  :focus {
    background: ${({ theme }) => (theme.selected ? '#353945 !important' : '#f4f5f6 !important')};
    div {
      color: ${({ theme }) => (theme.selected ? '#ffffff' : '#353945')};
    }
  }
`;
