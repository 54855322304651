import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format/index';
import { startOfWeekIntl } from '@plandok/core';

export const getWeekRange = (index: number) => {
  const date = addWeeks(startOfWeekIntl(new Date()), index);
  return [...Array(7)].map((_, i) => addDays(date, i));
};

export const formatDates = (date: Date) => format(date, 'yyyy-LL-dd');
