import React from 'react';
import { IconButton, Text } from '@plandok/core';
import { IntlTemplate } from '@plandok/i18n';
import * as SC from './styles';

interface BookFlowHeaderProps {
  step: number;
  title: string;
  onBack: () => void;
  onClose: () => void;
  isMobile: boolean;
}

export const BookingFlowHeader = ({ step, title, onBack, onClose, isMobile }: BookFlowHeaderProps) => (
  <SC.HeaderContainer>
    <SC.HeaderContent theme={{ step }}>
      {step === 1 ? <SC.EmptyArrowBlock /> : <IconButton icon="arrowSlider" onClick={onBack} size="40px" />}
      <SC.StepContainer>
        {!isMobile && (
          <Text size="xsmall" mb="none">
            <IntlTemplate label="market.ob.step.title" params={[step]} />
          </Text>
        )}
        <Text size="mlarge" mb="none" weight="semiBold" label={title} />
      </SC.StepContainer>
      <IconButton icon="close" onClick={onClose} size="40px" />
    </SC.HeaderContent>
  </SC.HeaderContainer>
);
