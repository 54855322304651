import React, { useContext, useEffect, useRef, useState } from 'react';
import { CustomIcon, Field, Text, useHistory, useParams, validateRequired } from '@plandok/core';
import { IntlTemplate, ReactIntl, getLabel } from '@plandok/i18n';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import useMediaQuery from 'hooks/screen/use-media-query';
import { CategoryItem } from 'pages/BookFlowPage/types';
import { MarketLocationService } from 'ghql';
import * as SC from './styles';

type SelectServiceRowProps = {
  currentService: MarketLocationService;
  allServices: MarketLocationService[];
  selectedServices?: string[];
  categories?: CategoryItem[];
  bookingLimit?: number;
};

export default function SelectServiceRow({
  currentService,
  allServices,
  selectedServices,
  categories,
  bookingLimit,
}: SelectServiceRowProps) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  const checkboxWrapper = useRef<HTMLDivElement>(null);
  const [rowDisabled, setIsRowDisabled] = useState(false);
  const { lang } = useContext(ReactIntl);
  const params: any = useParams();
  const history = useHistory();

  const slug = params?.slug;
  const navigateUrl = params.locale ? `/${lang}/${slug}/checkout` : `/${slug}/checkout`;
  const serviceId = currentService?.id;
  const selectedServicesCount = selectedServices?.length;
  const selected = selectedServices && selectedServices.includes(serviceId);

  useEffect(() => {
    setIsRowDisabled(bookingLimit ? bookingLimit === selectedServicesCount : false);
  }, [bookingLimit, selectedServicesCount]);

  const overlayShown = rowDisabled && !selected;

  const onServiceSelect = () => {
    (checkboxWrapper?.current?.children[0] as HTMLElement).click();

    if (!!allServices?.length) {
      const findSelectedService = allServices.find(({ id }) => id === serviceId);

      if (findSelectedService && categories) {
        const activeTitle = isMobile
          ? categories.findIndex(({ services }) => services.find(({ id }) => id === findSelectedService.id))
          : categories.find(({ services }) => services.find(({ id }) => id === findSelectedService?.id))!.title;

        history.push(navigateUrl, { ...findSelectedService, activeTitle });
      }
    }
  };

  const getParamsByServiceLimit = () => {
    const labels = ['one', 'two', 'three'];

    const quantityLabel = bookingLimit && getLabel(`market.ob.quantitativeLimit.${labels[bookingLimit - 1]}`, lang);

    const serviceLabelKey = bookingLimit === 1 ? 'serviceSingular' : 'servicesPlural';
    const serviceLabel = getLabel(`market.ob.quantitativeLimit.${serviceLabelKey}`, lang);

    return [quantityLabel, serviceLabel];
  };

  return (
    <div className="position-relative">
      {overlayShown && (
        <SC.HelpContainer theme={{ isMobile }}>
          <SC.HelpWrapper>
            <CustomIcon type="help" />
          </SC.HelpWrapper>
          <SC.LimitMessageContainer>
            <Text
              label="market.ob.quantitativeLimit.title"
              colorType="white"
              mb="none"
              size="small"
              weight="semiBold"
            />
            <Text mb="none" size="small">
              <IntlTemplate label="market.ob.quantitativeLimit.description" params={getParamsByServiceLimit()} />
            </Text>
          </SC.LimitMessageContainer>
        </SC.HelpContainer>
      )}

      <SC.CustomOverlay spinning={overlayShown}>
        <SC.ServiceItemContainer theme={{ selected }} onClick={onServiceSelect}>
          {overlayShown ? (
            <SC.Block />
          ) : (
            <div onClick={onServiceSelect} ref={checkboxWrapper}>
              <Field.Checkbox
                name="serviceIds"
                type="checkbox"
                value={serviceId}
                noFormBlock
                className="cm-checkbox"
                validate={validateRequired}
              />
            </div>
          )}

          <div className="w-100">
            <SC.ServiceTitle>
              <Text size={isMobile ? 'base' : 'medium'} mb="none" weight="semiBold" colorType="darkBrown">
                {currentService.name}
              </Text>
              {!!parseInt(currentService.price.amount) && (
                <Text size={isMobile ? 'base' : 'medium'} mb="none" weight="semiBold" colorType="darkBrown">
                  {currentService.price.amount} {currentService.price.currency}
                </Text>
              )}
            </SC.ServiceTitle>

            <SC.ServiceDuration>
              <Text size="small" mb="none">
                {currentService.duration}
              </Text>
              <Text size="small" mb="none" label="market.ob.service.duration.title" />
            </SC.ServiceDuration>

            <Text size="small" mb="none" colorType="brown">
              {currentService.description}
            </Text>
          </div>
        </SC.ServiceItemContainer>
      </SC.CustomOverlay>
    </div>
  );
}
