import React from 'react';
import { MarketLocationService, MarketLocationServiceGroups } from 'ghql';
import { BookFlowServices } from './components/BookFlowServices';
import { BookFlowFooter } from './components/BookFlowFooter';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import useMediaQuery from 'hooks/screen/use-media-query';
import FlowStatusBlock from 'components/FlowStatusBlock';

export default function ServiceSelectStep({ values }: any) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const serviceIds: string[] = values?.serviceIds;
  const { serviceLimit } = values?.partner || {};
  const serviceGroups: MarketLocationServiceGroups[] = values?.serviceGroups;
  const filteredServices: MarketLocationServiceGroups[] = serviceGroups?.filter(({ services }) => services.length);
  const mappedServices: MarketLocationService[] = [];
  const selectedServices: MarketLocationService[] = [];

  serviceGroups?.map(({ services }) => mappedServices.push(...services));

  serviceIds?.forEach((serviceId) => {
    const filteredServices = mappedServices?.filter(({ id }) => id === serviceId);

    selectedServices.push(...filteredServices);
  });

  const categories = filteredServices?.map((serviceGroup) => {
    const services: MarketLocationService[] = serviceGroup?.services;

    return {
      title: serviceGroup.name,
      description: serviceGroup.description,
      services,
    };
  });

  const Footer = <BookFlowFooter values={values} selectedServices={selectedServices} isMobile={isMobile} />;

  return (
    <div className="justify-between">
      {!!categories?.length && (
        <>
          <BookFlowServices categories={categories} selectedServices={serviceIds} bookingLimit={serviceLimit} />
          {!isMobile && <FlowStatusBlock values={values} />}
        </>
      )}
      {isMobile ? !!selectedServices.length && Footer : Footer}
    </div>
  );
}
