import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100vw;
  background: #f4f5f6;

  @media screen and (max-width: 1024px) {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  padding: 24px 0 48px;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: #2d2825;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 8px 25px;
    white-space: ${({ theme }) => theme.step !== 2 && 'nowrap'};
  }
`;

export const EmptyArrowBlock = styled.div`
  width: 40px;
`;

export const StepContainer = styled.div`
  text-align: center;

  div {
    :first-child {
      color: #777e90;
    }
    :last-child {
      line-height: 24px;
      color: #353945;
    }
  }

  @media screen and (max-width: 1024px) {
    div:last-child {
      line-height: 36px;
    }
  }
`;
