import './styles/index.less';
import '@plandok/core/styles/index.less';
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { IoC as CoreIoC, theme } from '@plandok/core';
import { getInitialLanguage, IntlProvider, IoC as IntlIoC } from '@plandok/i18n';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { AppRouter } from './AppRouter';
import GoogleAnalytics from './components/GoogleAnalytics';
import { graphqlClient } from './ghql';

// TODO configure IOC
IntlIoC.registerService({} as any);
CoreIoC.registerService({ useCountry: () => 'LT' } as any);

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_SENTRY_API_URL) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_API_URL,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
    });
  }

  if (process.env.REACT_APP_DATADOG_APP_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    // Datadog RUM Browser Monitoring init
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      sampleRate: 100,
      trackInteractions: true,
    });
  }
}

ReactDOM.render(
  <ApolloProvider client={graphqlClient}>
    <IntlProvider getInitialLanguage={getInitialLanguage}>
      <ThemeProvider theme={theme}>
        <GoogleAnalytics />
        <AppRouter />
      </ThemeProvider>
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('app')
);
