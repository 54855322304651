import { useElements } from '@stripe/react-stripe-js';
import { style } from 'pages/BookFlowPage/components/steps/PaymentStep/helpers';
import React, { useEffect } from 'react';
import * as SC from '../../styles';
import { Text, useStripeHook } from '@plandok/core';

const CardForm = ({ error }: { error: any }) => {
  const elements = useElements();
  const stripeHook = useStripeHook();

  useEffect(() => {
    if (elements) {
      stripeHook.loadComponent(elements, style);
    }
  }, [elements, stripeHook]);

  return (
    <SC.BlockPayCard>
      <SC.BorderWrapper id={'card-number-element-wrapper'} error={error?.code?.includes('number')}>
        <Text
          label={'input.cardNumber.label'}
          mb="none"
          size="small"
          weight="medium"
          colorType="darkBrown"
          ml="xxsmall"
        />
        <SC.Padding>
          <div id="card-number-element" />
        </SC.Padding>
      </SC.BorderWrapper>
      {error?.code?.includes('number') && (
        <Text
          label={error?.message}
          color={'#f5222d'}
          mb="none"
          size="small"
          weight="medium"
          colorType="darkBrown"
          ml="xxsmall"
        />
      )}
      <SC.CVVDateWrapper>
        <div>
          <SC.BorderWrapper id={'card-number-element-wrapper'} error={error?.code?.includes('expiry')}>
            <Text
              label={'market.ob.validThru.title'}
              mb="none"
              size="small"
              weight="medium"
              colorType="darkBrown"
              ml="xxsmall"
            />
            <SC.Padding>
              <div id="card-expiry-element" />
            </SC.Padding>
          </SC.BorderWrapper>
          {error?.code?.includes('expiry') && (
            <Text
              label={error?.message}
              color={'#f5222d'}
              mb="none"
              size="small"
              weight="medium"
              colorType="darkBrown"
              ml="xxsmall"
            />
          )}
        </div>
        <div>
          <SC.BorderWrapper id={'card-number-element-wrapper'} error={error?.code?.includes('cvc')}>
            <Text label={'CVC'} mb="none" size="small" weight="medium" colorType="darkBrown" ml="xxsmall" />
            <SC.Padding>
              <div id="card-cvc-element" />
            </SC.Padding>
          </SC.BorderWrapper>
          {error?.code?.includes('cvc') && (
            <Text
              label={error?.message}
              color={'#f5222d'}
              mb="none"
              size="small"
              weight="medium"
              colorType="darkBrown"
              ml="xxsmall"
            />
          )}
        </div>
      </SC.CVVDateWrapper>
    </SC.BlockPayCard>
  );
};

export default CardForm;
