import { Route, Router, Switch } from 'react-router-dom';
import React from 'react';
import ModalContainer from 'components/Modal/ModalContainer';
import { baseRoutes, routes } from './routesConfig';
import NotFoundPage from './pages/NotFoundPage';
import { RoutePath } from './constants/route';
import appHistory from './history';

export const AppRouter = () => (
  <Router history={appHistory}>
    <Switch>
      <Route path={RoutePath.BASE_URL}>
        {baseRoutes.map((route, index) => (
          <Route key={`${route.path}${index}`} path={route.path} component={route.component} exact={route.exact} />
        ))}
      </Route>
      {routes.map((route, index) => (
        <Route key={`${route.path}${index}`} path={route.path} component={route.component} exact={route.exact} />
      ))}
      <Route path="*" component={NotFoundPage} />
    </Switch>
    <ModalContainer />
  </Router>
);
