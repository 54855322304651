import { isBefore, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSunday, isSaturday } from 'date-fns';
import { formatDates } from 'helpers/date/booking-time';

export const weekdays = (loopDate: Date, loopEndDate: Date) => {
  const today = formatDates(new Date());
  const newWeekDays = [];

  while (loopDate <= loopEndDate) {
    newWeekDays.push({
      actualDate: formatDates(loopDate),
      date: new Date(loopDate),
      isToday: formatDates(loopDate) === today,
    });

    const newDate = loopDate.setDate(loopDate.getDate() + 1);
    loopDate = new Date(newDate);
  }

  return newWeekDays;
};

export const isDayOff = (day: Date, workingDays: string[]) => {
  const date = formatDates(day);
  const yesterday = new Date().setDate(new Date().getDate() - 1);

  return isBefore(day, yesterday) || !workingDays?.includes(date);
};

export const handleHeaderItemClick = (
  day: Date,
  workingDays: string[],
  helperForSelectDate: (day: Date) => void,
  setIsDaySelected: (isSelected: boolean) => void
) => {
  const disabled = isDayOff(day, workingDays);

  if (disabled) {
    return;
  } else {
    const timeSlotsContainer = document.querySelector('#timeSlotsContainer');

    helperForSelectDate(day);
    setIsDaySelected(true);

    if (timeSlotsContainer) {
      timeSlotsContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
};

export const handleHorizontalScroll = (
  setMonthInView: (date: string) => void,
  weekWrapper: HTMLDivElement | null,
  lasDay: string,
  setDateRange: (dates: string[]) => void,
  firDay: string,
  currentDay: HTMLDivElement | null
) => {
  const scrollObserver = (entries: IntersectionObserverEntry[]) => {
    const dates: string[] = [];

    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        dates.push((entry.target as any).dataset.date);
      }
    });

    if (dates.length) {
      setMonthInView(dates[0]);
    }
  };

  const observer = new IntersectionObserver(scrollObserver, { threshold: 1.0 });

  const getMonthAndYear = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return { month, year };
  };

  document.querySelectorAll('.day').forEach((day: Element) => observer.observe(day));

  if (weekWrapper && weekWrapper?.scrollLeft + weekWrapper?.clientWidth >= weekWrapper?.scrollWidth) {
    const date = new Date(lasDay);

    const { month, year } = getMonthAndYear(date);

    const lastDay = formatDates(new Date(year, month + 2, 0));

    setDateRange([firDay, lastDay]);
  } else if (weekWrapper && currentDay && weekWrapper?.scrollLeft <= 0) {
    const date = new Date(firDay);
    date.setMonth(date.getMonth() - 1);

    const { month, year } = getMonthAndYear(date);

    const firstDay = formatDates(new Date(year, month, 1));

    const newDays = (new Date(firDay).getTime() - date.getTime()) / 3600 / 24 / 1000;
    weekWrapper?.scrollTo(newDays * currentDay?.clientWidth, 0);
    setDateRange([firstDay, lasDay]);
  }
};

export const translateWeekDays = (day: Date) => {
  switch (true) {
    case isMonday(day):
      return 'calendar.mon';
    case isTuesday(day):
      return 'calendar.tue';
    case isWednesday(day):
      return 'calendar.wed';
    case isThursday(day):
      return 'calendar.thu';
    case isFriday(day):
      return 'calendar.fri';
    case isSaturday(day):
      return 'calendar.sat';
    case isSunday(day):
      return 'calendar.sun';
  }
};
