import { PaymentType } from '@plandok/core';
import create from 'zustand';
import { MarketEmployee, MarketLocation, MarketLocationService } from 'ghql';

type AssignedSpecialist = {
  id: string | null;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
};

type TrackerIds = { googleTrackerId: string | undefined; facebookTrackerId: string | undefined };

type State = {
  formValues: any;
  employeeId: string | null;
  paymentType?: PaymentType | null;
  paymentMethod?: string | null;
  employees: MarketEmployee[];
  assignedSpecialist: AssignedSpecialist;
  selectedServices: MarketLocationService[];
  selectedServiceId: string;
  trackerIds: TrackerIds;
  setTrackerIds: (trackerIds: TrackerIds) => void;
  setEmployeeId: (employeeId: string | null) => void;
  setEmployees: (employees: MarketEmployee[]) => void;
  setFormValues: (form: any) => void;
  setAssignedSpecialist: (assignedSpecialist: AssignedSpecialist) => void;
  setSelectedServices: (selectedServices: MarketLocationService[]) => void;
  setSelectedServiceId: (selectedServiceId: string) => void;
  setPaymentType: (paymentType: PaymentType) => void;
  setPaymentMethod: (paymentMethod: string) => void;
};

export const useStore = create<State>((set) => ({
  employeeId: '',
  employees: [],
  formValues: {},
  location: {} as MarketLocation | null | undefined,
  assignedSpecialist: {} as AssignedSpecialist,
  selectedServices: [],
  selectedServiceId: '',
  trackerIds: { googleTrackerId: '', facebookTrackerId: '' },
  setTrackerIds: (trackerIds: TrackerIds) =>
    set((state) => ({
      ...state,
      trackerIds,
    })),
  setEmployeeId: (employeeId) =>
    set((state) => ({
      ...state,
      employeeId: employeeId?.length ? employeeId : null,
    })),
  setEmployees: (employees) =>
    set((state) => ({
      ...state,
      employees,
    })),
  setFormValues: (formValues) =>
    set((state) => ({
      ...state,
      formValues,
    })),
  setAssignedSpecialist: (assignedSpecialist) =>
    set((state) => ({
      ...state,
      assignedSpecialist,
    })),
  setSelectedServices: (selectedServices) =>
    set((state) => ({
      ...state,
      selectedServices,
    })),
  setSelectedServiceId: (selectedServiceId) =>
    set((state) => ({
      ...state,
      selectedServiceId,
    })),
  setPaymentType: (paymentType: PaymentType) =>
    set((state) => ({
      ...state,
      paymentType,
    })),
  setPaymentMethod: (paymentMethod: string) =>
    set((state) => ({
      ...state,
      paymentMethod,
    })),
}));
